.duo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 48%;
    display: flex;
  }
}

.field {
  margin-bottom: 10px;
}
