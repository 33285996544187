.container {
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 4px;
}

.title {
  composes: headLine-4 from global;
  display: flex;
  align-items: center;
  &.high {
    color: var(--color-dracarys);
  }
  &.moderate {
    color: var(--color-blaze-orange);
  }
  &.low {
    color: var(--color-anzac);
  }
}

.icon {
  margin-right: 16px;
}

.desc {
  padding-left: 35px;
}

.link {
  margin: 0 5px;
  text-transform: lowercase;
  &,
  &:hover {
    color: var(--color-charcoal);
    font-weight: 500;
    text-decoration: underline;
  }
}
