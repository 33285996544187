@import "~sass/functions";

.column {
  display: flex;
  flex-direction: column;
}

.graph {
  border-radius: 4px;
}

.detail {
  &:nth-last-child(n + 2) {
    margin-bottom: 24px;
  }
}

.container {
  background-color: #fff;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 4px;
}
