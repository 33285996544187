@import "~sass/mixins";

.uploadDropBox {
  display: inline-flex;
  flex-direction: column;
  max-height: 220px;
  border-radius: 10px;
  cursor: pointer;

  .uploadField {
    width: 100%;
    height: 100%;
    :global {
      .ant-upload {
        width: 100%;
        height: 100%;
        margin: 0;
        border: none;
        background-color: transparent;
      }

      .ant-upload img {
        height: 100%;
        object-fit: cover;
      }

      .ant-upload > .ant-upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .ant-upload.ant-upload-select-picture-card {
        display: initial;
      }

      .ant-upload-select-picture-card > .ant-upload {
        padding: 0;
      }
    }
  }
}

.label {
  margin-bottom: 7px;
}

.error {
  border-color: var(--color-red-1);
}
