@import "~sass/functions";

.container {
  position: absolute;
  right: 10px;
  top: 70px;
  border-radius: 4px;
  overflow: hidden;
  // padding: 21px 0;
  background: #fff;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  height: 0px;
  width: 0px;

  transition: 0.1s width, 0.1s height;

  &.visible {
    width: 256px;
    height: auto;
    transition: 0.1s width, 0.1s height;
  }
}

.user {
  // padding: 20px 26px;
  text-align: center;
  border-bottom: 1px solid #e6f0f3;
  // margin-bottom: 20px;
  // padding-bottom: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 160px;
  display: flex;
  align-items: center;
  text-align: center;
  > div {
    margin: auto;
  }
}

.image {
  margin: auto;
  margin-bottom: 12px;
  height: 56px;
  width: 56px;
}

.name {
  font-size: pxToRem(18px);
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: capitalize;
  word-break: break-all;
}

.organization {
  font-size: pxToRem(12px);
  margin-bottom: 0;
  color: #7a7984;
}

.menu {
  display: flex;
  flex-direction: column;
  // padding: 0 26px;
}

.link {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  height: 48px;
  font-size: 14px;
  // &:nth-last-child(n + 2) {
  // margin-bottom: 27px;
  // }

  &:hover {
    background: #f6fafb;
    :global {
      .icon {
        color: #46b0c6;
      }
    }
  }

  &.highlighted {
    :global {
      .icon {
        color: #46b0c6;
      }
    }
  }

  :global {
    .icon {
      font-size: pxToRem(18px);
      color: #919fa1;
      margin-right: 22px;
      width: 20px;
    }
    span {
      color: #1b1b1b;
      font-weight: 400;
    }
  }
}

.divider {
  border-top: 1px solid #e6f0f3;
}

.highlighted {
  color: var(--color-pelorous) !important;
}
