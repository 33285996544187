@import "~sass/functions";

.container {
  background-color: #fff;
  border-radius: 4px;
}

.status {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: var(--color-capri);
  display: block;
  margin: auto;

  &.notSync {
    background-color: var(--color-concrete);
  }
}

.latestTemp {
  color: var(--color-capri);
  &.withFever {
    color: var(--color-dracarys);
    font-weight: 500;
  }
}

.nameActions {
  padding: 0 5px;

  &:nth-child(1) {
    padding-left: 0;
  }

  &:nth-last-child(n + 2) {
    border-right: 1px solid gray;
  }
}

.topPagination {
  margin-bottom: 24px;
}

.bottomPagination {
  margin-top: 24px;
}

.table {
  :global {
    .nameColumn {
      .label {
        display: none;
        font-size: pxToRem(12px);
        color: var(--color-asphalt);
        margin-bottom: 0;
        align-items: center;
      }
    }
    tr:hover {
      .nameColumn {
        .label {
          display: flex;
        }
      }
    }
  }
}

.manager {
  color: var(--color-apatite);
}

.circle {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 20px;
  background-color: var(--color-concrete);
  margin: 0 8px;
}
