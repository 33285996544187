@import "~sass/functions";

.questionField {
  :global {
    textarea {
      resize: none;
    }
  }
}

.autoAdd {
  :global {
    .checkboxLabel {
      font-size: 14px;
      font-weight: 500;
      color: #1b1b1b;
    }
  }
}

.placeholderField {
  :global {
    .compLabel {
      display: inline-flex;
      align-items: center;
    }
  }
  .icon {
    margin-left: 7px;
    line-height: 0;
    color: var(--color-green-1);
    font-size: pxToRem(13px);
  }
}

.placeholderTooltip {
  max-width: 184px;
  font-size: pxToRem(12px);
}

.questionField,
.fieldTypeField,
.autoAdd,
.placeholderField,
.loading {
  padding-left: 24px;
  padding-right: 24px;
}

.fieldTypeField {
  :global {
    .selectContainer {
      text-transform: capitalize;
    }
    .ant-select-selection__placeholder {
      text-transform: initial;
    }
  }
}
.fieldTypeDropdown {
  text-transform: capitalize;
}

.actionButton {
  margin-top: 0;
}

.actionsContainer {
  display: flex;
  margin: 20px 24px 0;
}
