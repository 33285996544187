.header {
  display: flex;
  align-items: center;
  h2 {
    margin: auto;
    margin-left: 10px;
  }
}

.description {
  color: #1b1b1b;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}

.text {
  margin-bottom: 16px;
  color: #1b1b1b;
}
