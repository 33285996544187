.actionButton {
  text-align: center;
  margin-top: 24px;
}

.list {
  margin-right: 8px;
}

.result {
  padding: 24px;
  background-color: var(--color-water);
  margin-bottom: 24px;
  border-radius: 4px;
}

.resultHeader {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.titleIcon {
  font-size: 18px;
  color: var(--color-pelorous);
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-apatite);
  margin: 0;
  margin-left: 8px;
}

.close {
  cursor: pointer;
  color: #919fa1;
}

.description {
  color: var(--color-apatite);
}

.errorContainer {
  padding: 12px 16px;
  background-color: #ffffff;
  border: 1px solid var(--color-cloudy);
  border-radius: 4px;
  max-height: 600px;
  overflow: scroll;
  max-width: 572px;
}

.errorTable {
  width: 100%;

  > thead > tr {
    color: var(--color-asphalt);
    font-size: 13px;

    > th {
      padding-bottom: 8px;
      font-weight: 500;
      padding-left: 4px;
    }

    > th:first-child {
      width: 52%;
    }
  }

  > tbody > tr {
    color: var(--color-charcoal);

    > td {
      font-weight: 400;
      padding: 4px 0;
      padding-left: 4px;
    }
  }
}

.message {
  font-size: 16px;
  color: #000000;
}

.loadingContainer {
  text-align: center;
}

.loadingText {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-charcoal);
  margin-bottom: 15px;
}
