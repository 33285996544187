@import "~sass/mixins";

.field {
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;

  &.fieldRangeContainer {
    height: 44px;
  }
  // margin-bottom: 12px;
}

.datePickerContainer {
  @include transition(border-color);
  border: 1px solid var(--color-gray-2);
  border-radius: 4px;

  &:hover:not(.disabled) {
    border-color: var(--color-blue-1);
  }

  &.loading {
    border: none;
    :global {
      .react-loading-skeleton {
        min-height: 48px;
        line-height: 2;
      }
    }
  }

  :global {
    // .ant-calendar-input-wrap {
    //   display: none;
    // }
    .ant-calendar-picker-input {
      min-height: 32px;
      padding: 0 18px;
      border: none;
      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
      }
      height: 100% !important;
      background: transparent !important;
    }
    .datePickerDropdown {
      .ant-calendar {
        margin-top: -5px;
        margin-left: -1px;
      }
    }
    .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: var(--color-blue-1);
    }
    .ant-calendar-range-picker-separator {
      padding-top: 10px;
    }

    .anticon.anticon-close-circle.ant-calendar-picker-clear {
      display: none;
    }
  }
}

.dropdownIcon {
  position: absolute;
  right: 15px;
  color: var(--color-green-1);
}

.label {
  composes: compLabel from global;
}

.message {
  composes: compMessage from global;
}

.error {
  > .datePickerContainer:not(:focus-within) {
    @include transition(border-color);
    border-color: var(--color-red-1);
  }

  > .message {
    color: var(--color-red-1);
  }
}
