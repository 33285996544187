@import "~sass/functions";

.field {
  display: flex;
  flex-direction: column;

  :global {
    .input-number,
    .ant-select,
    .ant-select-selection,
    .ant-calendar-picker-input,
    .ant-time-picker-input {
      height: 44px;
      width: 100%;
    }
    .ant-select-selection {
      display: flex;
      align-items: center;
    }
    .ant-time-picker-clear,
    .ant-calendar-picker-clear {
      display: none;
    }
    .ant-calendar-picker,
    .ant-time-picker {
      padding: 0px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.label {
  // display: block;
  composes: compLabel from global;
}

.message {
  composes: compMessage from global;
}

.error {
  :global {
    .ant-input {
      &:not(:focus) {
        border-color: var(--color-red-1);
      }
    }
  }
  > .message {
    color: var(--color-red-1);
  }
}
