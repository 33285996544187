.container {
  display: grid;
  grid-template-columns: 274px 1fr;
  grid-gap: 24px;
  max-width: 918px;
  margin: 24px auto 0;
}

.header {
  display: flex;
  align-items: center;
  grid-column: 1 / -1;
}

.headerTitle {
  composes: headLine-2 from global;
  margin-bottom: 0;
}

.settingsNav {
  align-self: flex-start;
}
