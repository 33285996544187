@import "~sass/functions";
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title,
.desc {
  text-align: center;
}

.image {
  max-width: 900px;
  margin-bottom: 90px;
}

.title {
  composes: headLine-1 from global;
}

.desc {
  font-size: pxToRem(20px);
  color: var(--color-charcoal);
  margin-bottom: 32px;
}

.action {
  &,
  &:hover {
    background-color: #fff;
    color: var(--color-winter);
  }
  composes: headLine-4 from global;
  border-radius: 48px;
  :global {
    span {
      font-weight: 500;
    }
  }
}
