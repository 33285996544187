.container {
  display: flex;
  margin-bottom: 24px;
}

.level {
  font-size: 16px;
  font-weight: 500;
  margin-right: 30px;
  padding: 20px 0;
  cursor: pointer;

  &.active {
    color: var(--color-apatite);
    border-bottom: 2px solid var(--color-apatite);
  }

  &.inactive {
    color: var(--color-mercury);
  }
}
