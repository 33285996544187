.noData {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  div {
    margin: auto;
  }
  h3 {
    font-size: 20px;
    color: #0a3637;
    font-weight: 500;
  }
  p {
    color: #7a7984;
    font-size: 16px;
  }
}

.table,
.unableToRetrieve {
  min-height: 492px;
}

.table {
  :global {
    .ant-table-thead > tr:first-child > th:first-child,
    .ant-table-tbody > tr > td:first-child {
      padding-left: 25px;
    }
    .ant-table-thead > tr:first-child > th:last-child,
    .ant-table-tbody > tr > td:last-child {
      padding-right: 25px;
    }
  }
}

.unableToRetrieve {
  flex-direction: column;
  :global {
    // .image {
    //   min-width: 230px;
    //   min-height: 200px;
    //   margin-bottom: 24px;
    // }
    .title {
      font-size: pxToRem(16px);
    }
    .message {
      text-align: center;
      max-width: initial;
      margin: 0;
    }
  }
}

.pagination {
  margin-top: 16px;
}

.emptyTable {
  display: flex;
  flex-direction: column;
  align-items: center;
}
