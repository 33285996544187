@import "~sass/mixins";
@import "~sass/functions";

.inputNumber {
  padding: 8px 18px;
  box-sizing: border-box;
  min-height: 46px;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  &[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:placeholder {
    color: var(--color-gray-1);
  }
}
