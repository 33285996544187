@import "~sass/functions";

.formModal {
  :global {
    .ant-modal-body {
      padding: 24px 0;
    }
    .header,
    .actions {
      margin-left: 24px;
      margin-right: 24px;
    }
    .body {
      display: flex;
      flex-direction: column;
      > *:nth-last-child(n + 2) {
        margin-bottom: 15px;
      }
    }
  }
}

.linkedQuestion {
  background-color: var(--color-yellow-6);
  border: 1px solid var(--color-yellow-7);
  padding: 12px 14px;
  display: flex;
  border-radius: 4px;
  color: var(--color-black-2);
  font-size: pxToRem(12px);
  margin: 0 24px;
  i {
    color: var(--color-yellow-7);
    font-size: pxToRem(20px);
    margin-right: 10px;
  }
}
