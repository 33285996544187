.header {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-gray-7);
  margin-bottom: 16px;
}

.backLink {
  color: var(--color-winter);
}

.orgAvatar {
  margin-left: 17px;
  margin-right: 12px;
  background-color: unset;
  height: 48px;
  width: 48px;
}

.orgName {
  color: #0a3637;
  font-size: 20px;
  font-weight: 500;
}

.subHeader {
  display: flex;
  align-items: center;
}

.role {
  color: var(--color-capri);
  font-size: 14px;
  text-transform: capitalize;
}

.circleSeparator {
  height: 4px;
  width: 4px;
  border-radius: 10px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: var(--color-concrete);
}

.dateJoined {
  color: var(--color-asphalt);
}

.subInfoContainer {
  padding-top: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-gray-7);
}

.subInfo {
  margin-bottom: 16px;
}

.subInfoTitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-asphalt);
  margin-bottom: 6px;
  text-transform: uppercase;
}

.subInfoDetail {
  font-size: 16px;
  color: var(--color-charcoal);
}

.leaveOrg {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.leaveOrgInfo {
  margin-right: 24px;
}

.leaveOrgName {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-apatite);
}

.leaveOrgDescription {
  font-size: 14px;
  color: var(--color-asphalt);
}

.leaveButton {
  height: 42px;
}
