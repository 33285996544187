.inputPillContainer {
  width: 100%;
  background-color: white;
  border: 1px solid var(--color-cloudy);
  border-radius: 4px;
  padding: 16px;
  padding-bottom: 8px;
  display: flex;
  overflow: scroll;
  flex-wrap: wrap;
  align-items: center;
  max-height: 150px;
}

.inputPillContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.inputPillContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.inputPillContainerFocused {
  border-color: var(--color-winter);
  transition: border-color 0.2s ease;
}

.pill {
  margin-right: 8px;
  margin-bottom: 8px;
}

.inputContainer {
  display: flex;
  align-items: center;
}

.input {
  // flex-grow: 1;
  border: none;
  // margin-bottom: 8px;
}

.inputClose {
  // margin-bottom: 8px;
  cursor: pointer;
  color: var(--color-asphalt);
  font-size: 12px;
}

.inputError {
  border-bottom: 1px dashed var(--color-dracarys);
}

.placeholder {
  color: var(--color-asphalt);
  margin-bottom: 8px;
}

.errorMessage {
  color: var(--color-dracarys);
  font-size: 12px;
  font-weight: 500;
}

.errorContainer {
  margin-top: 12px;
  max-height: 200px;
  overflow: scroll;
}

.errorContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.errorContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.loading {
  position: absolute;
  right: 40px;
}
