.item {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  .avatar {
    background-color: unset;
  }
}

.details {
  margin-left: 12px;
  margin-right: auto;
}

.orgPosition,
.orgName,
.positionDetails {
  margin-bottom: 0;
  margin-right: 5px;
}

.orgNameContainer {
  display: flex;
  align-items: center;
}

.positionDetails {
  composes: textSmall from global;
  display: flex;
  align-items: center;
}

.orgPosition {
  color: var(--color-capri);
}

.dateJoined {
  color: var(--color-asphalt);
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  display: inline-block;
  background-color: var(--color-concrete);
  margin: 0 8px;
}
