@import "~sass/functions";

.tableContainer {
  &.loading {
    :global {
      .ant-table-header-column {
        width: 100%;
      }
      .react-loading-skeleton {
        line-height: 1.5;
      }
    }
  }
  :global {
    .ant-table-thead > tr > th {
      overflow: unset !important;
    }
  }
  &.relaxedSide {
    :global {
      .ant-table-thead > tr > th:nth-child(1),
      .ant-table-tbody > tr > td:nth-child(1) {
        padding-left: 0;
      }
      .ant-table-thead > tr > th:nth-last-child(1),
      .ant-table-tbody > tr > td:nth-last-child(1) {
        padding-right: 0;
      }
    }
  }
  &.borderless {
    :global {
      .ant-table-tbody > tr > td {
        border: none;
      }
    }
  }
  &.noHover {
    :global {
      .ant-table-tbody > tr:hover > td {
        background-color: transparent;
      }
    }
  }
  :global {
    .ant-table-wrapper {
      width: 100%;
    }
    .ant-table-column-title {
      color: var(--color-asphalt);
    }
    .ant-table-thead > tr > th {
      background-color: transparent;
    }
    .ant-table-tbody {
      color: var(--color-black-1);
    }
    .ant-table-fixed-left {
      height: calc(100% - 14px);
    }
    .ant-table-fixed-header .ant-table-header,
    .ant-table-fixed-header .ant-table-header .ant-table-thead tr th {
      background-color: transparent;
    }
    .ant-table-body {
      overflow-y: auto !important;
    }
    .ant-table-body-inner {
      max-height: initial !important;
      overflow: hidden;
    }
    .ant-table-column-title,
    .ant-table-column-title .title,
    table > thead > th {
      font-weight: 500;
      font-size: pxToRem(13px);
      display: inline-flex;
      align-items: center;
    }
    .ant-table-column-title {
      font-size: pxToRem(13px);
      font-weight: 500;
    }
    .ant-table-tbody > tr > td {
      font-size: pxToRem(14px);
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .drop-over-downward td {
      border-bottom: 2px dashed var(--color-gray-8);
    }

    .drop-over-upward td {
      border-top: 2px dashed var(--color-gray-8);
    }

    .emptyTableHeader {
      display: block;
      font-size: pxToRem(24px);
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 5px;
    }

    .emptyTableDescription {
      font-size: pxToRem(14px);
      font-weight: normal;
      display: block;
      max-width: 460px;
    }
  }
}

tr th,
tr:last-child td {
  border-bottom: 0px !important;
}

.tableContainer.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.tableFirstColumn,
.tableLastColumn {
  display: flex;
  align-items: center;
  font-size: inherit;
}

.tableFirstColumn {
  display: flex;
  .dragIconWrapper {
    cursor: move;
  }
  .alignRight {
    margin-left: auto;
  }
}

.tableLastColumn {
  position: relative;
  :global {
    .optionIcon {
      font-size: pxToRem(3px);
      cursor: pointer;
      position: absolute;
      right: 0;
      height: 15px;
      width: 15px;
      display: flex;
      align-items: center;
    }
  }
}

.firstTableColumnData,
.lastTableColumnData {
  display: inline-block;
  font-size: inherit;
}

.firstTableColumnData {
  margin-left: 15px;
}

.lastTableColumnData {
  &.loading {
    width: 100%;
  }
  &:not(.loading) {
    margin-right: 30px;
  }
}

.lastTableColumnHeader {
  margin-right: 30px;
}

.alignRight {
  justify-content: flex-end;
}

.rowDropdown {
  position: absolute;
  right: 0;
  cursor: pointer;
  i {
    font-size: pxToRem(3px);
  }
}

.clickableRow {
  cursor: pointer;
}

.columnLoading {
  width: 100%;
  height: 100%;
}

.emptyContainer {
  padding: 20px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :global {
    .ant-empty-description {
      font-size: pxToRem(16px);
      font-weight: 500;
      color: var(--color-green-1);
      margin-bottom: 0;
    }
  }
}

.emptyTableTitle {
  composes: headLine-3 from global;
  color: var(--color-charcoal);
}

.emptyContainer.noImage {
  :global {
    .ant-empty-image {
      display: none;
    }
  }
}

.pagination {
  margin-top: 24px;
}

.columnActions {
  display: none;
  margin-top: 5px;
}

.tableRow:hover .hasColumnAction .columnActions {
  display: block;
}

.columnAction {
  composes: textSmall from global;
  cursor: pointer;
  color: var(--color-winter);
}
