@font-face {
  font-family: 'event-ico';
  src:  url('fonts/event-ico.eot?6r82mp');
  src:  url('fonts/event-ico.eot?6r82mp#iefix') format('embedded-opentype'),
    url('fonts/event-ico.ttf?6r82mp') format('truetype'),
    url('fonts/event-ico.woff?6r82mp') format('woff'),
    url('fonts/event-ico.svg?6r82mp#event-ico') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'event-ico' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-block:before {
  content: "\e958";
}
.ico-scan:before {
  content: "\e957";
}
.ico-wifi:before {
  content: "\e956";
}
.ico-community-building:before {
  content: "\e954";
}
.ico-dashboard-file:before {
  content: "\e955";
}
.ico-new-tab:before {
  content: "\e90e";
}
.ico-drag:before {
  content: "\e953";
}
.ico-verified:before {
  content: "\e950";
}
.ico-preference:before {
  content: "\e951";
}
.ico-mobile-phone:before {
  content: "\e952";
}
.ico-email:before {
  content: "\e94f";
}
.ico-thermometer-plus:before {
  content: "\e94c";
}
.ico-right-arrow:before {
  content: "\e94d";
}
.ico-member:before {
  content: "\e94e";
}
.ico-left-chevron-medium:before {
  content: "\e94a";
}
.ico-right-chevron-medium:before {
  content: "\e94b";
}
.ico-circle:before {
  content: "\e949";
}
.ico-pouchnation-circle:before {
  content: "\e943";
}
.ico-building:before {
  content: "\e944";
}
.ico-devices:before {
  content: "\e945";
}
.ico-globe:before {
  content: "\e946";
}
.ico-lock:before {
  content: "\e947";
}
.ico-pouchnation:before {
  content: "\e948";
}
.ico-asterisk:before {
  content: "\e90a";
}
.ico-meat-balls:before {
  content: "\e942";
}
.ico-export:before {
  content: "\e941";
}
.ico-caution:before {
  content: "\e940";
}
.ico-area:before {
  content: "\e93c";
}
.ico-dashboard:before {
  content: "\e93d";
}
.ico-location:before {
  content: "\e93e";
}
.ico-users:before {
  content: "\e93f";
}
.ico-map-center:before {
  content: "\e921";
}
.ico-profile-circle:before {
  content: "\e919";
}
.ico-eye-closed:before {
  content: "\e93a";
}
.ico-eye-open:before {
  content: "\e93b";
}
.ico-exclamation-fill:before {
  content: "\e904";
}
.ico-diarrhea:before {
  content: "\e92d";
}
.ico-conjunctivitis:before {
  content: "\e92e";
}
.ico-headache:before {
  content: "\e92f";
}
.ico-loss-of-smell-or-taste:before {
  content: "\e930";
}
.ico-rashes:before {
  content: "\e931";
}
.ico-chest-pain:before {
  content: "\e932";
}
.ico-breathing:before {
  content: "\e933";
}
.ico-toes:before {
  content: "\e934";
}
.ico-loss-of-speech-movement:before {
  content: "\e935";
}
.ico-bodyache:before {
  content: "\e936";
}
.ico-sore-throat:before {
  content: "\e937";
}
.ico-tiredness:before {
  content: "\e938";
}
.ico-dry-cough:before {
  content: "\e939";
}
.ico-check-fill:before {
  content: "\e91e";
}
.ico-filter:before {
  content: "\e915";
}
.ico-settings:before {
  content: "\e92b";
}
.ico-logout:before {
  content: "\e92c";
}
.ico-close-fill:before {
  content: "\e928";
}
.ico-info-fill:before {
  content: "\e927";
}
.ico-lightning:before {
  content: "\e924";
}
.ico-plus-staff:before {
  content: "\e925";
}
.ico-connect:before {
  content: "\e926";
}
.ico-search:before {
  content: "\e923";
}
.ico-access-points:before {
  content: "\e922";
}
.ico-attendees:before {
  content: "\e91a";
}
.ico-exhibitor:before {
  content: "\e91b";
}
.ico-overview:before {
  content: "\e91c";
}
.ico-document:before {
  content: "\e91d";
}
.ico-ticket:before {
  content: "\e920";
}
.ico-download:before {
  content: "\e917";
}
.ico-down-caret:before {
  content: "\e916";
}
.ico-descending:before {
  content: "\e911";
}
.ico-grid:before {
  content: "\e912";
}
.ico-list:before {
  content: "\e913";
}
.ico-ascending:before {
  content: "\e914";
}
.ico-credit-card:before {
  content: "\e90f";
}
.ico-bank:before {
  content: "\e910";
}
.ico-minus:before {
  content: "\e90d";
}
.ico-plus:before {
  content: "\e90c";
}
.ico-image:before {
  content: "\e90b";
}
.ico-info:before {
  content: "\e909";
}
.ico-close:before {
  content: "\e908";
}
.ico-down-chevron-thick:before {
  content: "\e906";
}
.ico-up-chevron-thick:before {
  content: "\e907";
}
.ico-hide-password:before {
  content: "\e918";
}
.ico-left-chevron:before {
  content: "\e902";
}
.ico-right-chevron:before {
  content: "\e903";
}
.ico-quote:before {
  content: "\e901";
}
.ico-check:before {
  content: "\e900";
}
.ico-printer:before {
  content: "\e91f";
}
.ico-send-airplane:before {
  content: "\e929";
}
.ico-left-chevron-thick:before {
  content: "\e92a";
}
.ico-show-password:before {
  content: "\e905";
}
