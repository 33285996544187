.container {
  padding: 140px 0;
  text-align: center;
}

.primaryText {
  color: var(--color-apatite);
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 8px;
}

.secondaryText {
  color: var(--color-asphalt);
  font-size: 14px;
}
