@import "~sass/functions";

.container {
  display: grid;
  grid-template-columns: 330px 1fr;
  grid-gap: 24px;
  max-width: 915px;
  margin: 30px auto 0;
}

.sympTitle {
  composes: textMedium from global;
  color: var(--color-apatite);
  margin-bottom: 24px;
}

.panelTitle {
  composes: headLine-3 from global;
  margin-bottom: 0;
}

.actions {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
}

.cancel {
  margin-right: 16px;
  color: var(--color-winter);
}

.delete {
  margin-right: auto;
}

.detailsPanel {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
}

.medicalPanel {
  :global {
    .checkboxLabel {
      font-weight: 500;
      font-size: pxToRem(16px);
      color: var(--color-apatite);
    }
  }
}

.medicalCheckbox {
  :global {
    .ant-checkbox-inner {
      min-width: 20px;
      min-height: 20px;
    }
    .ant-checkbox-inner:after {
      left: 32%;
    }
    .checkboxLabel {
      margin-left: 12px;
    }
  }
}

.logOtherSymptoms {
  width: 100%;
  resize: none;
}

.header {
  grid-column: 1 / -1;
}

.backLink {
  margin-bottom: 10px;
}

.title {
  composes: headLine-2 from global;
  margin-bottom: 0;
}
