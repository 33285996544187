@import "~sass/functions";

.container {
  display: flex;
  align-items: center;
}

.form {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.clearInput {
  composes: textSmall from global;
  position: absolute;
  right: 35px;
  max-width: 75px;
  max-height: 24px;
  text-transform: uppercase;
  padding: 4px 12px;
  border-radius: 24px;
}

.clearInputIcon {
  font-size: pxToRem(8px);
}

.clearInput,
.clearInput:hover {
  background-color: var(--color-sand);
  color: var(--color-winter);
}

.input {
  width: 100%;
  :global {
    .container,
    .ant-input {
      border-radius: 0;
      border-top-right-radius: 4px;
    }
    .ant-input-affix-wrapper .ant-input {
      padding-right: 140px;
    }
  }
}

.submitButton {
  height: 48px;
  border-radius: 0px 4px 4px 0px;
}

.inputButton {
  :global {
    .container,
    .ant-input {
      border-radius: 4px 0px 0px 4px;
    }
  }
}
