@import "~sass/functions";

.backLink {
  font-size: pxToRem(12px);
  justify-self: flex-start;
  display: inline-flex;
  align-items: center;
  color: var(--color-green-6);
}
.backLink:hover {
  color: var(--color-green-6);
}
