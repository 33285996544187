.input {
  min-height: 70px;
  max-width: 57px;
  text-align: center;
  border-radius: 4px;
  font-size: 32px;
  border: 1px solid var(--color-cloudy);
  font-family: ballinger-mono, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;

  &.error {
    border-color: var(--color-dracarys);
    color: var(--color-dracarys);
  }
}
