.backlink {
  width: 1150px;
  margin: auto;
  margin-top: 20px;
  display: flex;
}

.itemMenuContainer {
  margin-top: 0px;
  color: #ffff;
  border-radius: 4px;
  overflow: hidden;
}
.optionLabelContainer {
  padding-top: 15px;
  margin: auto;
}
.optionLabel {
  display: inline-flex;
}

.role {
  color: #0a3637;
  font-size: 13px;
  font-weight: 500;
}

.status {
  color: #46b0c6;
  font-size: 13px;
  font-weight: 500;
}

.mid {
  padding: 0px 5px;
  color: #aaadb9;
  font-size: 15px;
  font-weight: 500;
}

.gray {
  color: #919fa1;
  font-size: 13px;
  font-weight: 500;
}
