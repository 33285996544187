@import "~sass/mixins";
@import "~sass/functions";

.field {
  display: inline-flex;
  flex-direction: column;

  &.loading {
    width: 210px;
    min-height: 46px;

    :global {
      .react-loading-skeleton {
        min-height: 46px;
      }
    }
  }
}

.container {
  @include transition(border-color);
  border-radius: 4px;
  border: thin solid var(--color-gray-2);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:focus-within + .message {
    display: none;
  }
  &:hover,
  &:focus-within {
    border-color: var(--color-blue-1);
  }

  &.disabled {
    border: thin solid var(--color-gray-2);
  }

  &.disabled > .input::placeholder {
    color: var(--color-gray-2);
  }

  :global {
    .ant-input-group-wrapper,
    .ant-input-wrapper {
      display: flex;
    }
    .ant-input {
      border: 0;
    }
    .ant-input-group-wrapper {
      padding: 0;
    }
    .ant-input-group-addon {
      display: flex;
      align-items: center;
      width: initial;
      background-color: transparent;
      border: none;
      padding-left: 18px;
    }
    .ant-input-wrapper .ant-input {
      height: initial;
      box-shadow: none;
      border: none;
      padding-right: 18px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
      -webkit-appearance: none;
      margin: 0;
    }

    input:disabled {
      cursor: not-allowed;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--color-blue-6) inset;
    }

    input::placeholder {
      color: var(--color-gray-1);
    }
  }

  .count {
    position: absolute;
    top: 6px;
    right: 10px;
    z-index: 1;
  }

  .required {
    @include transition(border-color);
    border: 14px solid var(--color-cloudy);
    border-bottom-color: transparent;
    border-left-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
  }

  .requiredIcon {
    @include transition(border-color);
    color: var(--color-pelorous);
    font-size: pxToRem(8px);
    position: absolute;
    top: -10px;
    left: 2px;
  }
}

.input {
  padding: 13px 18px;
  box-sizing: border-box;
  width: 100%;
  border: none;
  min-height: 46px;

  &.hasCount {
    padding: 30px 18px 13px;
  }
}

.label {
  composes: compLabel from global;
}

.message {
  composes: compMessage from global;
}

.error:not(.disabled) {
  > .container:not(:focus-within) > .required {
    @include transition(border-color);
    border-top-color: var(--color-dracarys);
    border-right-color: var(--color-dracarys);
  }
  > .container:not(:focus-within) > .required > .requiredIcon {
    @include transition(border-color);
    color: #fff;
  }
  > .container:not(:focus-within) {
    @include transition(border-color);
    border-color: var(--color-red-1);
  }

  > .message {
    color: var(--color-red-1);
  }
}
.prefixed,
.suffixed {
  :global {
    .ant-input-affix-wrapper {
      padding: initial;
      min-height: initial;
    }

    .ant-input-affix-wrapper .ant-input {
      min-height: 46px;
    }
  }
}

.prefixed {
  :global {
    .ant-input-affix-wrapper .ant-input {
      padding: 13px 18px 13px 35px;
    }
  }
}

.suffixed {
  :global {
    .ant-input-affix-wrapper .ant-input {
      padding: 13px 30px 13px 18px;
    }
  }
}
