@import "~sass/functions";

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
}

.notifMarker {
  height: 10px;
  width: 10px;
  border-radius: 20px;
  background-color: var(--color-dracarys);
  position: absolute;
  top: 12px;
  right: 42px;
}

.image {
  margin-right: 15px;
  :global {
    .initials {
      background-color: #3494a8;
    }
  }
}

.icon {
  font-size: pxToRem(9px);
  color: #fff;
}
