.itemList {
  border: 1px solid #d4e0e4;
  padding: 12px 18px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.itemContent {
  width: calc(100% - 60px);
}

.status {
  border: 1px solid #d4e0e4;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 20px;
}

.icon {
  margin: auto;
  color: #aaadb9;
}

.addGateway {
  color: #319bc9;
  font-weight: 500;
  font-size: 16px;
}

.clickable {
  cursor: pointer;
  transition: background 1s;
  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
}

.gatewayItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gatewayLabel {
  color: #0a3637;
  font-weight: 500;
  font-size: 16px;
  max-width: 230px;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  word-break: break-all;
}

.macAddress {
  color: #7a7984;
  text-transform: uppercase;
  font-family: Consolas;
  font-size: 12px;
}

.statusIndentifier {
  height: 11px;
  width: 11px;
  border: 3px solid #ffffff;
  border-radius: 100%;
  margin-bottom: -25px;
  position: absolute;
  margin-left: 29px;

  &.connected {
    background: #00c366;
  }

  &.disconnected {
    background: #aaadb9;
  }

  &.notConfigured {
    background: #fc2f35;
  }
}

.reconfigure {
  color: #319bc9;
  font-weight: 500;
  font-size: 15px;
  margin-right: 20px;
  cursor: pointer;
}

.gatewayStatus {
  display: flex;
  align-items: center;
}
