@import "~sass/functions";
@import "~sass/mixins";

.container {
  background-color: var(--color-sand);
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.searchContainer {
  margin-bottom: 14px;
}

.search {
  width: 100%;
  :global {
    .container {
      border-radius: 0;
    }
    .container .ant-input {
      min-height: 56px;
      padding-right: 30px;
    }
  }
}

.userDetails {
  &.loading {
    width: 50%;
  }
}

.userListItems {
  max-height: calc(100vh - 132px);
  overflow-y: auto;
  height: 100%;
  &.loading {
    overflow-y: hidden;
  }
}

.userListItem {
  @include transition(background-color);
  background-color: #fff;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &:not(.loading) {
    cursor: pointer;
  }
  &:not(.loading):hover {
    background-color: var(--color-air);
  }
  &:not(.loading):active {
    background-color: var(--color-water);
  }
  &:nth-last-child(n + 2) {
    margin-bottom: 2px;
  }
}

.userName {
  composes: textMedium from global;
  color: var(--color-charcoal);
  margin-bottom: 0;

  &:not(.loading) {
    display: inline-block;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    text-decoration: underline;
  }

  :global {
    a {
      color: inherit;
      font-weight: inherit;
    }
  }
}

.location {
  font-size: pxToRem(14px);
  color: var(--color-apatite);
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
  &.inactive {
    color: var(--color-concrete);
  }
}

.inactiveIcon {
  vertical-align: middle;
  margin-right: 6px;
}

.temp {
  composes: textMedium from global;
  margin: 0;
  min-width: 60px;
  color: var(--color-winter);
  &.loading {
    width: 50px;
  }
}

.noResult {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  > div {
    margin: auto;
    > h2 {
      font-weight: 500;
      font-size: 20px;
    }
    > p {
      font-size: 16px;
      color: #7a7984;
    }
  }
}

.miniView {
  display: none;
}

.noUserPanel {
  background-color: transparent;
  margin: auto;
}
