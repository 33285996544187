.container {
  margin-top: 40px;
}

.pouchShop {
  height: 330px;
  margin-bottom: 10px;
  background: linear-gradient(262.25deg, #74d3ef 14.59%, #4dbbd1 78.69%), #42b1e1;
  background-blend-mode: lighten, normal;
  border-radius: 8px;
  margin-top: 20px;
  h3 {
    color: #ffffff;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 4px;
    padding: 20px;
  }
  .headerPouchShop {
    display: flex;
    justify-content: space-between;
    padding: 0px 24px;

    p {
      color: #0a3637;
      font-size: 14px;
    }
    .shopNoButton {
      height: 34px;
      background: #ffffff;

      color: #319bc9;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .closePouchShop {
    text-align: right;
    align-items: initial;
    vertical-align: top;
    display: contents;
    .image {
      width: auto;
      height: 100%;
    }
    .closePouchShopIcon {
      color: #ffffff;
      margin: 10px;
      font-size: 16px;
    }
  }
}
