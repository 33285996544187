@import "~sass/functions";

.container {
  background-color: #fff;
  border-radius: 4px;
}

.table,
.unableToRetrieve {
  min-height: 492px;
}

.table {
  :global {
    .ant-table-thead > tr:first-child > th:first-child,
    .ant-table-tbody > tr > td:first-child {
      padding-left: 25px;
    }
    .ant-table-thead > tr:first-child > th:last-child,
    .ant-table-tbody > tr > td:last-child {
      padding-right: 25px;
    }
  }
}

.unableToRetrieve {
  flex-direction: column;
  :global {
    // .image {
    //   min-width: 230px;
    //   min-height: 200px;
    //   margin-bottom: 24px;
    // }
    .title {
      font-size: pxToRem(16px);
    }
    .message {
      text-align: center;
      max-width: initial;
      margin: 0;
    }
  }
}

.contactNameColumn {
  display: flex;
  align-items: center;
}

.contactAvatar {
  margin-right: 16px;
}

.contactName {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-body-text);
  &:hover {
    text-decoration: underline;
  }
}

.emptyImage {
  min-height: 230px;
  margin-bottom: 24px;
}

.emptyTitle {
  composes: headLine-3 from global;
  text-align: center;
  margin-bottom: 12px;
}

.emptyDesc {
  margin: 0;
}

.pagination {
  margin-top: 16px;
}
