.container {
  text-align: center;
  padding: 16px;
  border-radius: 4px;
}

.image {
  width: 80%;
  height: auto;
}

.dateTime {
  display: flex;
}

.date {
  font-weight: 500;
  font-size: 18px;
  color: #24262a;
  margin-right: 10px;
}

.time {
  font-size: 18px;
  color: #7a7984;
}

.positive {
  background: #fc2f35;
}

.negative {
  background: #2a8be6;
}

.tag {
  font-size: 18px;
  color: #ffffff;
  margin-top: 15px;
  > span {
    margin-left: 10px;
    font-weight: 500;
  }
}

.summary {
  margin-top: 15px;
  font-size: 12px;
  color: #ffffff;
}
