.header {
  font-weight: 500;
  font-size: 18px;

  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .title {
    h1 {
      font-weight: 500;
      font-size: 18px;
      color: #0a3637;
    }
    .description {
      color: #1b1b1b;
      font-size: 14px;
    }
  }
}

.select {
  width: 100%;
}
