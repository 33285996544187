.container {
  display: flex;
  flex-direction: column;
}

.label {
  composes: compLabel from global;
  color: #727272;
  font-weight: 500;
  text-transform: uppercase;
}

.item {
  color: #000000;
  font-size: 14px;
}

.labelItem {
  word-break: break-all;
}
