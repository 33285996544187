@import "~sass/functions";

.compLabel {
  composes: textSmall from global;
  color: var(--color-charcoal);
  line-height: 20px;
  margin-bottom: 5px;
  font-size: pxToRem(12px);
}

.compMessage {
  font-size: pxToRem(12px);
  margin-top: 5px;
}

.ant-table-column-title {
  white-space: nowrap;
}

.ant-input-number-input {
  padding: 0;
}
