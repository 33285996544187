@import "~sass/functions";

.container {
  height: 100vh;
  padding: 0 20px;
  background-color: var(--color-sand);
  min-width: 1280px;
}

.content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 565px;
  height: 100%;
  max-width: 1280px;
  margin: auto;
}

.image {
  max-width: 805px;
  width: 100%;
}
.title {
  composes: headLine-1 from global;
}

.description {
  max-width: 500px;
  font-weight: normal;
  font-size: pxToRem(20px);
  color: var(--color-charcoal);
  margin-bottom: 40px;
}

.trySteps {
  font-size: pxToRem(20px);
  padding-left: 20px;
}
