.sharedInformation {
  padding-top: 24px;
}

.header {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-apatite);
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-apatite);
}

.subheaderContainer {
  margin-top: 24px;
}

.subheader {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-concrete);
}

.description {
  font-size: 12px;
  color: var(--color-asphalt);
}

.infoContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permission {
  font-size: 12px;
  color: var(--color-asphalt);
}

.select {
  width: 150px;
}
