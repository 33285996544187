@import "~sass/functions";

.container {
  background-color: #fff;
  padding: 24px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.countContainer {
  flex-grow: 1;
  color: var(--color-apatite);
  &.danger,
  &.danger > .count,
  &.danger > .new {
    color: var(--color-dracarys);
  }
  &.inactive,
  &.inactive > .count {
    color: var(--color-concrete);
  }
  &:nth-child(2) {
    max-width: 130px;
  }
  > .count {
    color: var(--color-charcoal);
  }

  .new {
    color: var(--color-capri);
  }

  &:nth-child(2) {
    margin-right: 20px;
  }
}

.title {
  composes: textMedium from global;
  margin-bottom: 4px;
}

.count {
  composes: headLine-2 from global;
  color: inherit;
  margin-bottom: 0;
}

.new {
  composes: textSmall from global;
  margin-bottom: 0;
}

.unableToRetrieve {
  width: 100%;
  padding: 0;
  margin-top: 21px;
  :global {
    .image {
      max-width: 44px;
      height: 44px;
    }
    .title {
      margin-bottom: 0;
      font-weight: normal;
      font-size: pxToRem(16px);
    }
  }
}
