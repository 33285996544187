.container {
  max-width: 384px;
  width: 100%;
  margin: auto;
  padding: 24px 16px;
  p {
    margin-top: 34px;
    font-size: 16px;
    color: #0a3637;
  }
}

.header {
  h1 {
    font-weight: 500;
    font-size: 24px;
    color: #0a3637;
  }
}

.logo {
  width: 280px;
}

.field {
  margin-top: 20px;
}

.input {
  width: 100%;
}

.mobileNumber {
  min-width: unset !important;
}

.inputMobileNumber {
  display: flex;
  justify-content: space-between;
  > div:first-child {
    width: 100px;
  }
  > div:nth-child(2) {
    width: calc(100% - 108px);
  }
  > div > div {
    width: 100%;
  }
}

.inputPassword {
  height: 46px;
}

.terms {
  color: #7a7984;
  font-size: 12px;
  margin-top: 16px;
}

.submit {
  margin-top: 16px;
  button {
    width: 100%;
  }
}

.loginNow {
  color: #7a7984;
  margin-top: 16px;
  font-size: 14px;
  a {
    margin-left: 5px;
    color: #319bc9;
    font-weight: 500;
  }
}

.termsLink {
  color: #7a7984;
  text-decoration: underline;
  &:hover {
    color: #7a7984;
    text-decoration: underline;
  }
}

.retry {
  font-weight: 500;
  font-size: 11px;
  color: red;
  cursor: pointer;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
