.emptyQuestion {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .image {
    margin-bottom: 30px;
  }

  > .text {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }
}
