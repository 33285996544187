.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
}

.total {
  min-width: 200px;
  color: var(--color-apatite);
  font-size: 18px;
  font-weight: 500;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.avatar {
  min-width: 40px;
  margin-right: 15px;
}

.name {
  color: var(--color-charcoal);
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.meatballs {
  color: #919fa1;
  cursor: pointer;
}

.status {
  border-radius: 100px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  padding: 2px 8px;

  &.active {
    background: var(--color-fern);
  }

  &.inactive {
    background: var(--color-concrete);
  }
}

.menuOption {
  min-width: 170px;
}
