.modal {
  max-width: 450px;
}

.body {
  // display: flex;
  // align-items: stretch;
  text-align: center;
  margin-bottom: 16px;

  p {
    color: #1b1b1b;
    font-size: 12px;
    margin-bottom: 16px;
  }
}

.input {
  width: 100%;
  text-align: left;
  :global {
    .ant-input {
      text-align: center;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.cancel {
  margin-right: 15px;
}

.remove,
.save {
  padding: 12px 24px;
}

.remove {
  margin-right: auto;
}

.wristbandImage {
  width: 60%;
}
