.header,
.questionContainer {
  display: grid;
  grid-template-columns: 1fr 80px;
}

.header {
  margin-top: 30px;
}

.columnName {
  color: var(--color-gray-8);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  &:nth-child(2) {
    text-align: center;
  }
}

.questionContainer {
  margin-top: 20px;
  padding-bottom: 16px;
  &:nth-last-child(n + 2) {
    border-bottom: 1px solid #edeef3;
  }
}

.question {
  color: var(--color-green-1);
  margin: 0;
  cursor: pointer;
  word-break: break-word;
}

.autoAdd {
  justify-self: center;
}
