.emptyContainer {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px;
}

.noOrg {
  color: #0a3637;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.findOut {
  color: #0a3637;
  font-size: 14px;
  text-align: center;
}

.findOutLink {
  font-size: 14px;
}
