@import "~sass/mixins";

.container {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 18px;
  font-weight: 500;
  background-color: #fff;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.18);
}

.icon {
  margin-right: 15px;
}

.error {
  background-color: #fc2f35;
  color: #fff;
}

.success {
  background: #00c366;
  color: #fff;
}

:global {
  .ant-message {
    top: 75px;
  }
  .ant-message-notice-content {
    padding: 0;
    box-shadow: none;
  }
  .ant-message-custom-content > i {
    display: none;
  }
}
