@import "~sass/functions";
.modal {
  height: 100%;
  margin: 0;
  padding: 0;
  &.modal.modal {
    max-width: 100%;
  }
  :global {
    .ant-modal-content {
      height: 100%;
      border-radius: 0;
    }
    .header {
      justify-content: flex-end;
      margin: 0;
    }
    .header > .close {
      font-size: pxToRem(24px);
      color: #444444;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 76px;
      .body {
        max-width: 382px;
        width: 100%;
      }
    }
    .ant-modal-body .body {
      align-self: center;
      justify-self: center;
      margin: auto;
    }
  }
}

.title {
  composes: headLine-2 from global;
}

.desc,
.resendDesc {
  color: #000;
}

.resendDesc {
  margin: 0;
}

.resend,
.changeEmail {
  padding: 0;
}

.email {
  font-weight: 500;
}

.errorMessage {
  margin-bottom: 32px;
  .changeEmail {
    font-size: 12px;
    color: inherit;
    &,
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 767px) {
  .modal {
    :global {
      .ant-modal-body {
        padding: 30px;
        .body {
          max-width: 520px;
        }
      }
    }
  }
}
