@import "~sass/functions";

.container {
  display: flex;
  justify-content: center;
  background-color: var(--color-blue-11);
  padding-bottom: 15px;
  font-size: pxToRem(12px);
  padding-left: 26px;
  padding-right: 26px;
  min-width: 850px;
  > *:nth-last-child(n + 2) {
    margin-right: 32px;
  }
}
