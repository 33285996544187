@import "~sass/functions";

.learnMore {
  display: inline-block;
  margin-left: 5px;
}

.learnMoreIcon {
  margin-left: 5px;
}

.riskDetailsModal {
  max-width: 450px;
  :global {
    .title {
      font-size: pxToRem(18px);
      color: #24262a;
    }
  }
}

.title {
  composes: textMedium from global;
  margin-bottom: 4px;
}

.desc {
  color: var(--color-charcoal);
}

.highRisk {
  color: var(--color-dracarys);
}

.moderateRisk {
  color: var(--color-blaze-orange);
}

.lowRisk {
  color: var(--color-anzac);
}

.cautionIcon {
  margin-right: 10px;
}

.veryLowRisk {
  color: var(--color-asphalt);
}

.temperature {
  font-weight: 500;
  font-size: pxToRem(14px);
}

.riskLevelPill {
  color: #fff;
  border-radius: 100px;
  padding: 2px 8px;
  text-transform: capitalize;
  font-size: pxToRem(13px);
  font-weight: 500;
  &.highRisk {
    background-color: var(--color-dracarys);
  }
  &.moderateRisk {
    background-color: var(--color-blaze-orange);
  }
  &.lowRisk {
    background-color: var(--color-anzac);
  }
}

.noDataContainer {
  min-height: 220px;
  :global {
    .noDataImage {
      right: -15px;
    }
    .noDataDescription {
      max-width: 160px;
    }
  }
}
