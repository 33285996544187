.otpCode {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(37px, 1fr));
  grid-column-gap: 8px;
  grid-row-gap: 12px;
  margin-bottom: 10px;
}

.submit {
  width: 100%;
  margin-bottom: 24px;
  margin-top: 32px;
}

.messageError {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  grid-column: 1 / -1;
  color: var(--color-dracarys);
}

@media (max-width: 767px) {
  .otpInput {
    max-width: 100%;
  }
}
