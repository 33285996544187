.submit {
  max-width: 564px;
  margin: auto;
  text-align: left;
  margin-top: 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 30px;
}

.textButton {
  padding-left: 0px;
}

.back {
  padding: 0;
  justify-self: flex-start;
}

.submitButton {
  border-radius: 24px;
  justify-self: center;
  padding: 12px 40px;
}

@media (max-width: 999px) {
  .submit {
    grid-template-columns: 1fr;
    margin-top: 24px;
    grid-row-gap: 30px;
    justify-content: center;
  }
  .submitButton {
    grid-row: 1;
    width: 100%;
    font-size: 16px;
  }

  .back {
    justify-self: center;
    font-size: 16px;
  }
}
