@import "~sass/functions";

.container {
  display: flex;
  position: fixed;
  top: 0;
  background-color: var(--color-pelorous);
  max-height: 60px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  z-index: 5;
}

.homeLink {
  min-width: 180px;
  align-self: center;
}

.menu {
  margin-left: 50px;
  display: flex;
}

.usersLink {
  :global {
    .icon {
      font-size: pxToRem(23px);
      width: 23px;
    }
  }
}
