@import "~sass/mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > label {
    color: #7a7984;
    font-size: 12px;
    font-weight: 500;
    padding: 25px 0px 20px 0px;
    text-transform: uppercase;
    &:last-child {
      margin-right: 10px;
    }
  }
}

.questions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.removeQuestion {
  color: rgba(145, 159, 161, 0.6);
  font-size: 12px;
  margin-left: 20px;
  transition: 2s color;
  &:hover {
    color: var(--color-salsa);
  }
}

.allowManual {
  margin-right: 10px;
  display: flex;
}

.actions {
  display: flex;
  align-items: center;
}

.label {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
}

.questionName {
  word-break: break-all;
  margin-right: 10px;
}
