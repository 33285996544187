.container {
  max-width: 564px;
  text-align: left;
  margin: auto;

  h1 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 24px;
    color: #0a3637;
    word-break: break-word;
  }

  p {
    font-size: 16px;
  }
}

.description {
  font-size: 12px;
  color: #7a7984;
}

.link {
  font-size: 12px;
  color: #46b0c6;
  cursor: pointer;
}

.content {
  background: #ffffff;
  text-align: center;
  padding: 48px;
}

.avatar {
  width: 120px;
  height: 120px;
  margin: auto;
}

.upload {
  width: 120px;
  height: 120px;
  margin: auto;
  border-radius: 100%;
  overflow: hidden;
  &:hover {
    .uploadHover {
      margin-top: -105% !important;
    }
    .avatar {
      background: rgba(10, 54, 55, 0.8);
      // opacity: 0.8;
      img {
        opacity: 0.2;
      }
    }
  }
}

.uploadStyle {
  display: unset !important;
  :global {
    .ant-upload.ant-upload-select {
      width: 100%;
    }
  }
}

.uploadHover {
  cursor: pointer;
  transition: margin-top 0.5s;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1111;
  margin-top: -300%;
  text-align: center;
  > p {
    margin: auto;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
  }
}
