.header {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-apatite);
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-apatite);
}

.subheaderContainer {
  margin-top: 24px;
}

.subheader {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-concrete);
}

.description {
  font-size: 12px;
  color: var(--color-asphalt);
}

.infoContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permission {
  font-size: 12px;
  color: var(--color-asphalt);
}

.select {
  width: 150px;
}

.field {
  margin-top: 20px;
  .headerField {
    margin-bottom: 10px;
  }
}

.dateAndTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 48%;
  }
}

.datePicker {
  // height: 44px;
  width: 100%;
  :global {
    .ant-calendar-picker-input {
      height: 44px;
    }
    // .ant-calendar-picker-icon {
    //   margin: auto;
    // }
    .anticon-close-circle {
      display: none;
    }
  }
}
