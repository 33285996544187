.dropdownReport {
  display: inline-flex;
  background-color: #319bc9;
  border-radius: 4px;
  color: #ffffff;
  box-shadow: none;
  cursor: pointer;
  width: 240px;
  padding: 8px 16px;
  min-height: 41px;
  justify-content: center;
  align-items: center;
  // text-align: center;
}

.arrowIcon {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.dropdownReport:hover {
  background-color: #319bc9;
  color: #ffffff;
  border-radius: 4px solid #319bc9;
}

.dropdownReport:active,
.dropdownReport:focus {
  background-color: #319bc9;
  color: #ffffff;
  border-radius: 4px solid #319bc9;
}

.menuItems {
  line-height: 16px;
  text-align: start;
  color: #798287;
  padding: 10px 20px;
  // padding-left: 20px;
}

.menuItems:hover {
  text-align: start;
  background-color: #e7f7ff;
}

.menuItems:active {
  color: #575757;
  font-weight: 600;
}

.downloadButton {
  margin-left: auto;
  margin-right: 8px;
}

.dropdownOptions {
  padding: 0px;
}

.p1 {
  margin-bottom: 0px;
}

.p2 {
  color: #798287;
  margin-bottom: 0px;
}