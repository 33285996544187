@import "~sass/functions";

.container {
  height: 100vh;
  padding: 0 20px;
  background-color: var(--color-sand);
  min-width: 1280px;
}

.content {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 565px;
  height: 100%;
  max-width: 1280px;
  margin: auto;
}

.image {
  max-width: 740px;
}

.title {
  composes: headLine-1 from global;
}

.description {
  font-size: pxToRem(20px);
  max-width: 700px;
  font-weight: normal;
  margin-bottom: 40px;
}

.checkWithAdmin {
  display: block;
  margin-top: 40px;
}

.action {
  &,
  &:hover {
    background-color: #fff;
    color: var(--color-winter);
  }
  composes: headLine-4 from global;
  border-radius: 48px;
}
