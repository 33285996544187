@import "~sass/functions";

.modal {
  max-width: 620px;
  :global {
    .title {
      font-size: pxToRem(24px);
    }
    .close {
      font-size: pxToRem(16px);
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.desc {
  color: #000;
}

.email {
  width: 100%;
  margin-bottom: 5px;
  :global {
    .compMessage {
      font-weight: 500;
    }
  }
}

.note {
  composes: textSmall from global;
  color: var(--color-asphalt);
  margin-bottom: 24px;
}

.error {
  color: var(--color-red-1);
  font-weight: 500;
  font-size: 12px;
}
