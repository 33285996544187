@import "~sass/mixins";
@import "~sass/functions";

.field {
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;

  :global {
    .ant-select {
      display: block;
      width: 100%;
    }
    .ant-select-selection {
      border: none;
      min-height: 46px;
    }
    .ant-select-open .ant-select-selection {
      border-color: var(--color-blue-1);
      box-shadow: none;
    }
    .ant-select-focused .ant-select-selection {
      box-shadow: none;
    }
    .ant-select-selection__rendered {
      height: 100%;
    }

    .ant-select-selection {
      display: flex;
      align-items: center;
    }
    .ant-select-selection > .ant-select-selection__rendered {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 18px;
    }

    .ant-select-dropdown {
      box-shadow: 0px 4px 10px rgba(10, 54, 55, 0.08);
    }

    // Menu item hover state
    .ant-select-dropdown-menu-item {
      padding: 8px 16px;
    }

    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: var(--color-blue-7);
    }
    // Menu item selected state
    .ant-select-dropdown-menu-item-selected,
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: var(--color-blue-3);
    }

    .ant-select-dropdown-menu-item-selected {
      color: var(--color-green-1);
      font-weight: 500;
    }

    .ant-select-selection--multiple {
      .ant-select-selection__choice {
        padding-right: 10px;
        & > .ant-select-selection__choice__remove {
          display: none;
        }
      }

      .ant-select-selection__choice {
        border-radius: 4px;
        background-color: var(--color-water);
      }
      .ant-select-selection__choice__content {
        font-size: pxToRem(12px);
        font-weight: 500;
        color: var(--color-charcoal);
      }

      .ant-select-selection__choice,
      .ant-select-selection__choice__remove,
      .ant-select-selection__choice__remove:hover {
        color: var(--color-charcoal);
      }

      .ant-select-arrow {
        top: 50%;
      }
    }
  }
}

.field.basic .container {
  border-color: transparent;
  &:hover,
  &.active {
    border-color: transparent;
  }
  :global {
    .ant-select-selection {
      min-height: initial;
      background-color: transparent;
    }
    .ant-select-selection__rendered,
    .ant-select-arrow {
      color: var(--color-green-1);
    }
    .ant-select-selection__rendered {
      margin-right: 35px;
      font-weight: 500;
    }
    .ant-select-arrow {
      margin-top: -5px;
    }
    .ant-select-arrow-icon {
      font-size: pxToRem(10px);
    }
  }
}

.container {
  @include transition(border-color);
  border-radius: 4px;
  border: 1px solid var(--color-cloudy);
  display: flex;
  align-items: center;
  position: relative;
  min-width: 200px;
  &:hover,
  &.active {
    border-color: var(--color-blue-1);
  }

  &.disabled {
    cursor: not-allowed;
    border: 1px solid var(--color-gray-2);
    color: var(--color-gray-2);
  }
  .required {
    @include transition(border-color);
    border: 14px solid var(--color-cloudy);
    border-bottom-color: transparent;
    border-left-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
  }

  .requiredIcon {
    @include transition(border-color);
    color: var(--color-pelorous);
    font-size: pxToRem(8px);
    position: absolute;
    top: -10px;
    left: 2px;
  }
}

.field.basic .label {
  font-size: pxToRem(14px);
  color: var(--color-gray-8);
  line-height: 30px;
}

.label {
  composes: compLabel from global;
}

.message {
  composes: compMessage from global;
}

.icon {
  position: absolute;
  right: 15px;
}

.error {
  > .container:not(:focus-within) > .required {
    @include transition(border-color);
    border-top-color: var(--color-dracarys);
    border-right-color: var(--color-dracarys);
  }
  > .container:not(:focus-within) > .required > .requiredIcon {
    @include transition(border-color);
    color: #fff;
  }
  > .container:not(:focus-within) {
    @include transition(border-color);
    border-color: var(--color-red-1);
  }

  > .message {
    color: var(--color-red-1);
  }
}

.dropdown {
  :global {
    .ant-select-dropdown-menu-item {
      padding: 5px 18px;
    }
  }
}
