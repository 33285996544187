.container {
  margin-top: 20px;

  max-height: 600px;
  overflow: auto;
}

.emptyContainer {
  margin-top: 20px;
  height: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  > div {
    width: 100%;
    margin: auto;
    text-align: center;
  }
  h4 {
    font-weight: 500;
    font-size: 16px;
    color: #0a3637;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    color: #7a7984;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: 14px;
  text-transform: uppercase;
  overflow: hidden;
  &:not(:last-child) {
    border-bottom: 1px solid #e6f0f3;
  }
  .close {
    margin-right: -50px;
    color: rgba(145, 159, 161, 0.6);
    transition: 0.3s margin-right;
  }

  &:hover {
    .close {
      margin-right: 0px;
    }
  }
}
