.activeOrg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}

.orgCountTitle {
  margin: 0;
  color: var(--color-charcoal);
  composes: textMedium from global;
}

.orgCount {
  color: var(--color-concrete);
  font-weight: inherit;
}

.activeCountTitle,
.inviteRequestLink {
  font-weight: 500;
}

.emptyOrgList {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .image {
    max-width: 315px;
  }
}

.emptyOrgListTitle {
  composes: headLine-3 from global;
}

.emptyOrgListDesc {
  font-size: pxToRem(14px);
  color: var(--color-charcoal);
}

.doYouWant {
  margin-bottom: 0;
}
