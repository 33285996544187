@import "~sass/functions";
.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  background-color: var(--color-sand);
  min-width: 1280px;
}

.image {
  max-width: 1040px;
}

.title {
  composes: headLine-1 from global;
}

.description {
  font-size: pxToRem(20px);
  text-align: center;
  max-width: 620px;
  font-weight: normal;
}

.innerDesc {
  display: inline-block;
  margin-top: 40px;
}
