@import "~sass/mixins";

.datePicker {
  padding: 7px 0;
  box-sizing: border-box;
  width: 100%;

  &.error {
    > div {
      :global {
        .ant-calendar-picker-input {
          border-color: red;
        }
      }
    }
  }
}

.rangeContainer {
  height: 100%;
  height: 46px;
  border: 1px solid var(--color-gray-2);
  border-radius: 4px;
}

.disabled {
  :global {
    .ant-calendar-picker {
      background-color: #f5f5f5 !important;
    }
  }
}

@media (max-width: 1024px) {
  :global {
    .datePickerDropdown {
      .ant-calendar-input-wrap {
        display: none;
      }
    }
  }
}
