.loading {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #bbb;
}

.container {
  > h4 {
    font-weight: 500;
    font-size: 16px;
    color: #1b1b1b;

    span {
      margin-left: 5px;
      color: #aaadb9;
    }
  }
}
