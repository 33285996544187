@import "~sass/functions";

.container {
  padding: 16px 24px;
  background-color: #f3f3f3;
  border-radius: 4px;
}

.container.error {
  color: var(--color-red-1);
  background-color: #ffe3e3;
}

.container.warning {
  color: #000;
  background-color: var(--color-buff);
}

.message {
  font-size: pxToRem(12px);
  font-weight: 500;
  margin: 0;
}

.notif {
  background: #fef9e6;
  border: 1px solid #efdd9a;
}
