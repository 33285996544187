.modal {
  max-width: 620px !important;
}

.subheader {
  color: var(--color-charcoal);
}

.warning {
  display: flex;
  align-items: flex-start;
  padding: 12px 18px;
  margin-top: 24px;
  background-color: var(--color-highlight);
  border: 1px solid var(--color-custard);
  border-radius: 4px;
}

.info {
  color: var(--color-custard);
  margin-right: 10px;
  font-size: 20px;
  padding-top: 2px;
}

.warningText {
  color: var(--color-charcoal);
}

.field {
  margin-top: 24px;
}

.fieldTitle {
  color: var(--color-asphalt);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 12px;
}

.actions {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
