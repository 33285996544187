.avatarContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.avatar {
  margin-right: 16px;
  width: 80px;
  height: 80px;
  :global {
    .initials {
      font-size: pxToRem(28px);
    }
  }
}
.avatarActions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 250px;
}

.uploadGuide {
  composes: textSmall from global;
  color: #9b9ca5;
  margin-top: 8px;
  margin-bottom: 0;
}

.uploadContainer {
  :global {
    .uploadField .ant-upload {
      align-items: flex-start;
    }
  }
}
