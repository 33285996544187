.container {
  padding: 20px;
  display: flex;
  align-items: center;
}

.formItem {
  height: 44px;

  > div {
    width: 100%;
    height: 100%;
  }
}

.searchKey {
  width: 70%;
  margin-right: 20px;
}

.clear {
  background: #e6f0f3;
  border-radius: 20px;
  height: 22px;
  display: flex;
  align-items: center;
  color: #299acb;
  font-size: 12px;
  padding: 0 10px;
  cursor: pointer;
  margin-right: 10px;
}

.removeIcon {
  margin-left: 10px;
  font-size: 10px;
}

.noData {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  div {
    margin: auto;
  }
  h3 {
    font-size: 20px;
    color: #0a3637;
    font-weight: 500;
  }
  p {
    color: #7a7984;
    font-size: 16px;
  }
}
