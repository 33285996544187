.dropdown {
  border: 2px solid #319bc9;
  padding: 12px;
  width: 100%;
  border-radius: 4px;
  color: #319bc9;
  font-weight: 500;
  font-size: 14px;
}

.icon {
  margin-left: 10px;
}

.itemMenuContainer {
  margin-top: 0px;
  color: #ffff;
  border-radius: 4px;
  overflow: hidden;
}
.optionLabelContainer {
  padding-top: 15px;
  margin: auto;
}
.optionLabel {
  display: inline-flex;
}

.role {
  color: #0a3637;
  font-size: 13px;
  font-weight: 500;
}

.status {
  color: #46b0c6;
  font-size: 13px;
  font-weight: 500;
}

.mid {
  padding: 0px 5px;
  color: #aaadb9;
  font-size: 15px;
  font-weight: 500;
}

.gray {
  color: #919fa1;
  font-size: 13px;
  font-weight: 500;
}
