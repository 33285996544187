.quarantineStatus {
  position: absolute;
  bottom: 10px;
  left: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  padding: 12px 16px;
  // min-width: 250px;
  background-color: #fff;
  border-radius: 4px;
  // min-width: 340px;
  width: 350px;

  > .header {
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-bottom: 8px;

    &.inZone {
      color: var(--color-fern);
    }

    &.outZone {
      color: #fe0221;
    }

    > .statusMessage {
      margin-left: 6px;
      font-weight: 500;
    }
  }

  > .currentLocationContent {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    > .currentLocation {
      > .title {
        font-size: 16px;
        color: #00074d;
        font-weight: 500;
        margin-bottom: 4px;
      }

      > .address {
        color: #aaaaaa;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }

    > .chevron {
      cursor: pointer;
      color: #3983ff;

      &.expand {
        transform: rotate(90deg);
      }
    }
  }

  > .expandedContent {
    margin-top: 16px;

    .title {
      font-size: 12px;
      color: #aaaaaa;
    }

    .description {
      color: #231f20;
      font-size: 12px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }

    > .info {
      margin-bottom: 16px;
    }
  }
}
