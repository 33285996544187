.container {
  :global {
    .ant-modal-content {
      width: 620px;
    }
  }
}

.header {
  h3 {
    font-weight: 500;
    font-size: 20px;
    color: #0a3637;
  }
  p {
    font-size: 14px;
    color: #1b1b1b;
  }
}

.content {
  display: flex;
  align-items: center;
}

.list {
  width: 325px;
}

.background {
  width: calc(100% - 325px);
  padding-left: 17px;
}

.link {
  font-weight: 500;
  font-size: 15px;
}

.item {
  padding: 20px 0px;
  &:not(:last-child) {
    border-bottom: 1px solid #e6f0f3;
  }
}
