.passwordStrength {
  width: 100%;
  margin-top: 10px;
}

.passwordStrengthLabel {
  // font-weight: 500;
  font-size: 12px;
  width: 100%;
  margin-top: 8px;
  // text-align: center;
}

.passwordBars {
  width: 100%;
  background: #d4e0e4;
  border-radius: 4px;
  height: 4px;
}

.bar {
  height: 4px;
  transition: width 1s;
  // width: 23%;
  // margin-left: 2%;
}

.GREAT {
  color: #00c366;
  &:not(.passwordStrengthLabel) {
    background: #00c366;
    width: 100%;
  }
}

.AVERAGE {
  color: #e9bc31;
  &:not(.passwordStrengthLabel) {
    background: #e9bc31;
    width: 75%;
  }
}

.WEAK {
  color: #ff6b00;
  &:not(.passwordStrengthLabel) {
    background: #ff6b00;
    width: 50%;
  }
}

.POOR {
  color: red;

  &:not(.passwordStrengthLabel) {
    background: red;
    width: 25%;
  }
}

// .POOR {
//   &.second,
//   &.third,
//   &.fourth {
//     display: none;
//   }
// }

// .WEAK {
//   &.third,
//   &.fourth {
//     display: none;
//   }
// }

// .AVERAGE {
//   &.fourth {
//     display: none;
//   }
// }
