@import "~sass/functions";

.container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  background-color: var(--color-sand);
}

.image {
  max-width: 715px;
  width: 100%;
  max-height: 350px;
  height: 100%;
}

.title {
  composes: headLine-1 from global;
  text-align: center;
}

.loginNowContainer,
.description {
  font-size: pxToRem(16px);
  text-align: center;
  font-weight: normal;
}

.loginNow {
  &,
  &:hover {
    background-color: #fff;
    color: var(--color-winter);
  }
  display: inline-block;
  border-radius: 48px;
  margin-left: 16px;
  padding: 12px 24px;
  composes: headLine-4 from global;
  :global {
    span {
      font-weight: 500;
    }
  }
}

@media (max-width: 375px) {
  .loginNowContainer {
    display: flex;
    flex-direction: column;
    .loginNow {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}
