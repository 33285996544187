@import "~sass/functions";

.image {
  max-width: 185px;
  min-height: 160px;
  margin-bottom: 16px;
}

.title {
  composes: headLine-3 from global;
  color: var(--color-apatite);
}

.desc {
  font-size: pxToRem(16px);
  text-align: center;
  margin: 0;
}

.placeholderContainer {
  text-align: center;
}

.diary {
  padding: 20px 0px;
  // &:last-child {
  //   margin-top: 20px;
  // }
  margin-bottom: 20px;
}

.header {
  font-weight: 500;
  font-size: 16px;
  color: #0a3637;
}

.container {
  margin-bottom: 20px;
}

.headerContainer {
  padding: 0px 26px;

  padding-bottom: 0px;
}

.headerTitle {
  font-size: 18px;
  color: #0a3637;
}

.titleToday {
  font-size: 16px;
  margin: 0px 30px 20px 30px;
  h2 {
    font-size: 16px;
  }
}

.pastdaysRow {
  display: flex;
  justify-content: space-between;
}

.icon {
  color: #919fa1;
  font-size: 12px;
}

.notif {
  margin: 20px;
}

.info {
  color: #efdd9a;
  margin-right: 10px;
  font-size: 16px;
}

.infoContainer {
  font-size: 16px;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.privacyContainer {
  text-align: center;
  height: 200px;
  display: flex;
  align-items: center;
  color: #919fa1;
  > div {
    margin: auto;
  }
  h2 {
    font-size: 16px;
    font-weight: normal;
  }
  p {
    color: #7a7984;
    font-size: 14px;
  }
}

.diaryPastDaysModal {
  max-width: 800px;
  :global {
    .title {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .header {
      padding: 24px 24px 0;
      margin-bottom: 24px;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}

.titleNavContainer {
  user-select: none;
  .nav {
    font-size: pxToRem(12px);
  }
}

.diaryBreakdown {
  :global {
    .header {
      display: none;
    }
    .ant-table-body {
      max-height: 420px;
    }
  }
}

.diaryBreakdownTable {
  :global {
    .ant-table-body {
      max-height: 420px !important;
    }
  }
}

.currentData {
  display: inline-block;
  min-width: 260px;
  text-align: center;
}

.diaryBreakdownTempAvg {
  width: initial;
  margin: 0 24px;
}

.locationColumn {
  text-overflow: initial;
  white-space: pre-wrap;
  word-break: break-all;
}
