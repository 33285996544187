.description {
  font-size: 16px;
  color: #000000;
}

.submit {
  margin-top: 24px;
  text-align: right;
}

.orgName {
  font-weight: 500;
}
