.container {
  text-align: center;

  h1 {
    margin: auto;
    font-weight: 500;
    font-size: 24px;
    color: #0a3637;
    margin-bottom: 20px;
  }

  p {
    font-size: 12px;
    color: #7a7984;
    span {
      font-size: 12px;
    }
  }
}

.pouchBands {
  display: flex;
  align-items: center;
}

.item {
  text-align: center;
  .image {
    width: 100%;
  }
}

.step {
  border: 2px solid #dbe9ed;
  border-radius: 20px;
  padding: 5px 20px;
  width: fit-content;
  font-weight: 500;
  font-size: 13px;
  color: #0a3637;
  margin: auto;
  margin-bottom: 20px;
}

.deviceUid {
  margin-top: 20px;
}
.input {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  :global {
    .ant-input {
      text-align: center;
    }
  }
}

.submit {
  width: 100%;
  text-align: left;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.submitButton {
  border-radius: 24px;
}

.textButton {
  padding-left: 0px;
}
