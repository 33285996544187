@import "~sass/mixins";

.field {
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;

  :global {
    // .ant-time-picker-panel-input-wrap {
    //   display: none;
    // }
    .ant-time-picker-input {
      min-height: 32px;
      border: none;
      padding: 0;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.timePickerContainer {
  @include transition(border-color);
  border: 1px solid var(--color-gray-2);
  border-radius: 4px;

  &:hover:not(.disabled) {
    border-color: var(--color-blue-1);
  }

  &.loading {
    border: none;
    :global {
      .react-loading-skeleton {
        min-height: 48px;
        line-height: 2;
      }
    }
  }
}

.label {
  color: var(--color-charcoal);
  line-height: 20px;
  margin-bottom: 1px;
  font-size: 0.85714rem;
}

.message {
  font-size: pxToRem(12px);
  margin-top: 5px;
}

.error {
  > .timePickerContainer:not(:focus-within) {
    @include transition(border-color);
    border-color: var(--color-red-1);
  }

  > .message {
    color: var(--color-red-1);
  }
}
