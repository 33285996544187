.container {
  background-color: #fff;
  padding: 24px;
  overflow: hidden;
  min-height: 300px;
}

.title {
  composes: headLine-4 from global;
  margin-bottom: 23px;
  color: var(--color-charcoal);
}
