.input {
  padding: 12px 18px;
  box-sizing: border-box;
  :global {
    .ant-input {
      min-height: 48px;
    }
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:placeholder {
    color: var(--color-gray-1);
  }
}
