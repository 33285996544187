@import "~sass/functions";

.map {
  height: 100%;
  :global {
    .live-map-cluster-marker {
      display: flex;
      align-items: center;
      font-size: pxToRem(16px);
      font-weight: 500;
      color: #fff;
      > div:focus {
        outline: none;
      }
    }
    .live-map-cluster-marker.is-mini-map-marker {
      pointer-events: none;
    }
  }
}

.markerInfoFullName {
  composes: textMedium from global;
  margin: 0;
  text-align: center;
  font-size: initial;
  &,
  &:hover {
    color: var(--color-charcoal);
  }
}

.markerInfoTemp {
  composes: headLine-3 from global;
  color: var(--color-winter);
  margin: 0;
  text-align: center;
}

:global {
  .gm-style-iw {
    .gm-ui-hover-effect {
      display: none !important;
    }
  }
}

.clusterBreakdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  padding: 10px;
  > .name {
    font-size: 16px;
    font-weight: 600;
    max-width: 70%;
    word-break: break-all;
  }
}
