.container {
  max-width: 1160px;
  margin: auto;
  display: grid;
  grid-template-columns: 448px 1fr;
  grid-column-gap: 27px;
}

.column {
  display: flex;
  flex-direction: column;
}

.detail {
  &:nth-last-child(n + 2) {
    margin-bottom: 24px;
  }
}

.map {
  min-height: 500px;
  margin-bottom: 26px;
}

.graph {
  border-radius: 4px;
}

.noDataContainer {
  :global {
    .noDataTitle,
    .noDataDescription {
      max-width: 290px;
    }
    .noDataDescription {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .noDataImage {
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

.learnMore {
  display: inline-block;
  margin-bottom: 9px;
}
