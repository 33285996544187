.container {
  display: grid;
  grid-template-columns: minmax(413px, 590px) minmax(611px, 1fr);
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left;
  background-position-y: 100%;
}

.sidePanel {
  padding: 32px;
  max-width: 430px;
  justify-self: center;
  padding-top: 80px;
}

.logo {
  width: 300px;
}

.content {
  margin-top: 135px;

  h1 {
    font-weight: 500;
    font-size: 36px;
  }
  p {
    margin-top: 24px;
    font-size: 18px;
  }
}

.backgroundImage {
  position: absolute;
  left: -10px;
  bottom: -10px;
  width: 400px;
}

.children {
  display: flex;
  background: #e6f0f3;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.taskNav {
  display: grid;
}

.link {
  font-weight: 500;
  font-size: 18px;
  &:nth-last-child(n + 2) {
    margin-bottom: 40px;
  }
}

.prev {
  color: #919fa1;
  > i {
    color: #46b0c6;
  }
}

.next {
  color: #0a3637;
}

.active {
  color: #46b0c6;
}

@media (max-width: 1023px) {
  .container {
    grid-template-columns: 1fr;
    background-position-y: 160px;
    background-size: 260px;
    background-position-x: 100%;
  }

  .content {
    margin-top: 50px;
  }

  .link {
    &:nth-last-child(n + 2) {
      margin-bottom: 25px;
    }
  }

  .sidePanel {
    max-width: initial;
    padding-top: 50px;
    width: 100%;
  }
  .children {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .logo {
    width: 190px;
  }
  .content {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
}
