.container {
  border: 1px solid #d4e0e4;
  padding: 20px;
  border-radius: 12px;
  width: calc(100% - 60px);
  margin: 10px 30px;
  display: flex;
  justify-content: space-between;

  h6,
  h1 {
    margin-bottom: 0px;
  }
}

.items {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.label {
  font-size: 12px;
  color: #7a7984;
}

.temp {
  font-size: 24px;
}

.status {
  font-weight: 500;
  font-size: 24px;
}

.tempContainer {
  font-size: 24px;
}

.normal {
  color: #46b0c6;
}

.black {
  color: black !important;
}
