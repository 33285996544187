@import "~sass/functions";
.container {
  display: grid;
  margin: 30px 0;
  grid-column: 1 / -1;
  grid-template-columns: minmax(400px, 500px) 1fr;
}

.title {
  composes: headLine-2 from global;
  margin-bottom: 5px;
  color: var(--color-charcoal);
}

.lastUpdate {
  font-size: pxToRem(12px);
  color: var(--color-asphalt);
  margin-bottom: 0;
  grid-column: 1;
}

.selectTemp {
  grid-column: 2;
  grid-row: 1;
  justify-self: flex-end;
  align-self: flex-start;
}
