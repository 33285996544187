.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
}

.title {
  composes: headLine-2 from global;
  margin-bottom: 25px;
}

.desc {
  margin-bottom: 28px;
}

.email,
.sendResetLink {
  margin-bottom: 28px;
}

.backToLogin,
.resendNow {
  composes: buttonText from global;
}

.action {
  align-self: flex-start;
}

.backToLoginSubmitted {
  margin-bottom: 28px;
}

.message {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .form {
    max-width: 100%;
  }
  .sendResetLink,
  .backToLoginSubmitted {
    width: 100%;
  }
}
