.container {
  background-color: #fff;

  position: relative;
  border-radius: 4px;
}

.image {
  min-height: 400px;
  margin-bottom: 12px;
  object-fit: cover;
  width: 100%;
}

.title {
  composes: headLine-2 from global;
}

.action {
  padding: 32px 24px;
}

.desc {
  color: var(--color-asphalt);
}
