.input {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  :global {
    .inputField {
      width: 100%;
    }
  }
}

.tooltip {
  margin-left: 10px;
}

.icon {
  color: #6c8687;
}

.actionButton {
  text-align: right;
  margin-top: 20px;
}
