.form {
  display: flex;
  justify-content: space-between;
  width: inherit;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.panel {
  width: 100%;
}

.panelTitle {
  font-weight: 500;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin-bottom: 0px;
  }
  &:not(.noBorder) {
    border-bottom: 1px solid #edeef3;
    padding-bottom: 20px;
  }
}

.formContainer {
  width: 100%;
}

.formContent {
  width: 100%;
  // margin-top: 16px;
  padding: 16px 0;
  &:not(:first-child):not(.passwordForm) {
    border-top: 1px solid #edeef3;
  }
  > div {
    width: 100%;
  }
}

.editButton {
  font-weight: 500;
  font-size: 14px;
  color: #319bc9;
  cursor: pointer;
}

.input {
  width: 100%;
  > div > div {
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.label {
  color: #919fa1;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 20px;
}
.value {
  color: #1b1b1b;
  font-size: 14px;
}

.formLabel {
  color: #24262a;
  font-size: 12px;
  text-transform: capitalize;
}

.mobileNumber {
  min-width: unset !important;
}

.inputMobileNumber {
  display: flex;
  justify-content: space-between;
  > div:first-child {
    width: 100px;
  }
  > div:nth-child(2) {
    width: calc(100% - 108px);
  }
  > div > div {
    width: 100%;
  }
}

.toastContainer {
  display: flex;
  align-items: center;
}

.toast {
  margin-left: 20px;
  font-weight: 500;
  font-size: 18px;
}
