@import "~sass/functions";

.modal {
  max-width: 450px;
  :global {
    .header {
      margin-bottom: 24px;
      color: var(--color-body-text);
    }
  }
}

.title {
  margin-bottom: 4px;
  color: var(--color-apatite);
  font-size: pxToRem(15px);
}

.row {
  display: flex;
  align-items: center;
}

.tempNameContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--color-smoke);
  padding: 20px 0;
}

.row:first-child > .tempNameContainer {
  border-top: none;
}

.circle {
  background: black;
  // background: linear-gradient(180deg, #46b0c6 -56.87%, #236092 99.07%);
  width: 12px;
  height: 12px;
  border-radius: 12px;
  // margin-right: 12px;

  &.low {
    background: linear-gradient(180deg, #46b0c6 -56.87%, #236092 99.07%);
  }

  &.normal {
    background: linear-gradient(180deg, #46b0c6 57.81%, #236092 149.75%);
  }

  &.higherThan {
    background: linear-gradient(180deg, #fc2f35 -35.38%, #46b0c6 47.31%);
  }

  &.high {
    background: linear-gradient(180deg, #fc2f35 -12.5%, #46b0c6 86.64%);
  }

  &.veryHigh {
    background: linear-gradient(180deg, #fc2f35 6.65%, #46b0c6 142.43%);
  }

  &.extremelyHigh {
    background: linear-gradient(180deg, #fc2f35 37.99%, #46b0c6 163.3%);
  }
}

.info {
  font-size: 15px;
  color: var(--color-apatite);
  font-weight: 500;
}

.name {
  margin-left: 12px;
}
