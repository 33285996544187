.input {
  width: 100%;
}

.label {
  color: #7a7984;
  font-size: 12px;
  font-weight: 500;
  padding: 25px 20px;
}

.question {
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.5s background;
  word-break: break-all;
  &:hover {
    background: rgba(145, 159, 161, 0.2);
  }
}

.askQuestion {
  color: #319bc9;
}

.searching {
  color: #7a7984;
}
