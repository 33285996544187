.container {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
}

.header {
  margin-bottom: 25px;
  > h4 {
    color: #0a3637;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  > p {
    font-size: 14px;
    color: #24262a;
  }
}
