@import "~sass/functions";

.container {
  max-width: 1150px;
  margin: 24px auto 0;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
}

.title {
  color: var(--color-apatite);
  font-size: 32px;
  font-weight: 500;
}

.createButton {
  border-radius: 24px;
}
