.container {
  display: flex;
  align-items: center;
  padding: 75px 40px;
}

.leftContainer {
  padding: 0 40px;
}

.title {
  font-size: 24px;
  color: var(--color-apatite);
  font-weight: 500;
  margin-bottom: 15px;
}

.description {
  font-size: 16px;
  color: var(--color-charcoal);
  margin-bottom: 32px;
}

.createButton {
  border-radius: 24px;
}
