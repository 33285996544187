.container {
  width: 1150px;
  margin: auto;
  margin-top: 20px;
  display: flex;
}

.content {
  width: calc(100% - 350px);
  margin-left: 20px;
}

.tabContent {
  margin-top: 20px;
}

.profileInfo {
  width: 330px;
}

.mapLocation {
  height: 250px;
  width: 100%;
}

.diary {
  width: 450px;
}

.sides {
  width: calc(100% - 470px);
  margin-left: 20px;
}
