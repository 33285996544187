@import "sass/functions";

.container {
  border: 1px solid var(--color-gray-10);
  border-radius: 16px;
  padding: 24px;
}

.date {
  color: var(--color-asphalt);
  font-size: pxToRem(12px);
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.info {
  font-weight: 400;
}

.info.prop,
.info.required {
  font-weight: 500;
}

.info.notRequired {
  color: var(--color-concrete);
}

.note {
  font-size: 14px;
}

.dateEffectivity {
  font-weight: 500;
}
