.container {
  display: flex;
}

.item {
  font-size: 0.85714rem;
  font-weight: 400;
  &:not(:last-child) {
    border-right: 1px solid grey;
  }
  &:not(:first-child) {
    padding-left: 10px;
  }
  padding-right: 10px;
}

.danger {
  color: red;
}
