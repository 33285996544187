.tempType {
  :global {
    .selectContainer {
      min-width: 120px;
    }
    .ant-select-selection {
      min-height: 41px;
      background-color: var(--color-winter);
    }
    .ant-select-selection,
    .ant-select-arrow {
      color: #fff;
    }
  }
}
