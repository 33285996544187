.container {
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 30px 30px 10px;
}

.panel {
  min-height: 600px;
  padding: 30px 30px 10px 30px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
  button {
    margin: auto;
    margin-top: 20px;
    width: 100%;
  }
}

.name {
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  word-break: break-word;
}

.temperature {
  color: #2a8be6;
  font-weight: 500;
  font-size: 36px;
}

.link {
  cursor: pointer;
  color: #2a8be6;
}

.image {
  margin-bottom: 20px;
}

.avatar {
  width: 207px;
  height: 207px;
  margin: 0 auto 16px;
  font-size: 5rem;
  :global {
    .initials {
      font-size: 5rem;
    }
  }
}

.deleteUser {
  margin-top: 20px;
}

.info {
  padding: 24px 24px;
  border-top: 1px solid #e6f0f3;

  transition: height 1s;
}

.information {
  margin-left: -30px;
  margin-right: -30px;
}

.infoIcon {
  color: #919fa1;
}

.infoIconContainer {
  width: 30px;
  text-align: center;
}

.contactInfo {
  display: flex;
  &:last-child {
    margin-top: 20px;
  }
}

.contactInfoContainer {
  margin-bottom: 20px;
}

.lastSync {
  color: #7a7984;
  font-size: 12px;
  margin-top: 10px;
}
