.container {
  display: flex;
  align-items: flex-end;
}

.number {
  flex-grow: 1;
  margin-right: 8px;
}

.inputNumber {
  width: 100%;
}

.word {
  flex-grow: 1;
}
