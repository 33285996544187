.container {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 400px;
  padding: 20px;
  > div {
    margin: auto;
  }
}

.title {
  color: #1b1b1b;
  font-weight: 500;
  font-size: 16px;
  margin-top: 24px;
}

.description {
  color: #7a7984;
  font-size: 14px;
  margin-top: 9px;
}

.noImage {
  min-height: unset !important;
  height: unset !important;
}
