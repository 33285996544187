.break-word-all {
  word-break: break-all !important;
}

.semi-bold {
  font-weight: 500 !important;
}
.bold {
  font-weight: bold !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalized {
  text-transform: capitalize !important;
}

.cDanger {
  color: var(--color-dracarys) !important;
}

.cInactive {
  color: var(--color-concrete) !important;
}

.nodata {
  padding: 20px;
}
