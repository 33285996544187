.avatarContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.avatar {
  margin: auto;
  width: 80px;
  height: 80px;
  :global {
    .initials {
      font-size: pxToRem(28px);
    }
  }
}

.content {
  text-align: center;

  h1 {
    color: #0a3637;
    font-size: 24px;
    font-weight: bolder;
    margin: 20px auto;
  }

  h4 {
    color: #1b1b1b;
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 20px;
  }
  p {
    color: #1b1b1b;
    font-size: 14px;
  }
}

.button {
  font-size: 16px;
}

.container {
  width: 600px;
  :global {
    .ant-modal-content {
      width: 600px;
    }
  }
}
