@import "~sass/functions";

.symptoms {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
}

.symptomButton {
  width: 100%;
  &.eleven {
    grid-column: 2;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.title {
  font-size: pxToRem(12px);
  text-align: center;
  margin-bottom: 0;
}

.iconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-air);
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-bottom: 7px;
  &.select {
    background-color: #fff;
    border: 1px solid var(--color-water);
  }
}

.icon {
  color: var(--color-cloudy);
  font-size: pxToRem(40px);
  &.select {
    color: var(--color-winter);
  }
}

.check {
  position: absolute;
  color: var(--color-winter);
  font-size: pxToRem(16px);
  bottom: -3px;
  right: 5px;
}
