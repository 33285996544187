.modal {
  :global {
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .avatar {
    height: 80px;
    width: 80px;
    margin-bottom: 16px;
  }
  .title,
  .message {
    text-align: center;
  }

  .create {
    max-width: 430px;
    width: 100%;
  }
  .continue {
    border-radius: 40px;
  }
}
