.container {
  max-width: 1150px;
  margin: 24px auto 0;
}

.header {
  display: block;
  align-items: center;
  margin-bottom: 24px;
  p {
    color: #1b1b1b;
    font-size: 16px;
  }
}

.title {
  composes: headLine-1 from global;
  margin-bottom: 0;
}

.form {
  display: flex;
  justify-content: space-between;
  width: inherit;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.panel {
  width: 564px;
  background: #ffffff;
  border-radius: 4px;
  padding: 23px 24px;
}

.input {
  width: 100%;
  > div {
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.inputMobileNumber {
  display: flex;
  justify-content: space-between;
  > div:first-child {
    width: 100px;
  }
  > div:nth-child(2) {
    width: calc(100% - 108px);
  }
  > div > div {
    width: 100%;
  }
}

.label {
  color: #919fa1;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 20px;
}
.value {
  color: #1b1b1b;
  font-size: 14px;
}

.submit {
  display: flex;
  margin-top: 24px;
  > button:nth-last-child(n + 2) {
    margin-right: 15px;
  }
  > .cancel {
    margin-left: auto;
  }
}

.compLabel {
  font-size: 0.85714rem;
}

.address {
  input {
    height: 64px;
  }
}

.mobileNumber {
  min-width: unset !important;
}

.workPhone {
  width: 100%;
}

.submitModal {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  > button {
    margin-left: 20px;
  }
}
.avatarContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.avatar {
  margin-right: 16px;
  width: 80px;
  height: 80px;
  :global {
    .initials {
      font-size: pxToRem(28px);
    }
  }
}

.avatarActions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 245px;
}

.uploadContainer {
  margin-right: 24px;
  :global {
    .uploadField .ant-upload {
      align-items: flex-start;
    }
  }
}

.uploadGuide {
  composes: textSmall from global;
  color: #9b9ca5;
  margin-top: 8px;
  margin-bottom: 0;
}

.removePhoto {
  padding: 0;
}

.message {
  border-radius: 4px;
  justify-self: flex-start;
  margin-bottom: 14px;
  :global {
    .message {
      display: flex;
      align-items: center;
    }
  }
}

.warningIcon {
  margin-right: 10px;
}

.blockquote {
  width: 564px;
  margin: auto;
  margin-right: 0px;
  color: #7a7984;
  font-size: 12px;
}

.authorizationHeader {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-apatite);
}

.authField {
  display: flex;
  align-items: flex-start;
}

.authField:first-child {
  margin-bottom: 16px;
  margin-top: 24px;
}

.authDesc {
  color: var(--color-charcoal);
  margin-left: 16px;
  a {
    color: #319bc9;
  }
}

.authCheckbox {
  margin-top: 2px;
}
