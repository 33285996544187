.container {
  font-size: 20px;
  color: #d4e0e4;
  width: 20px;
  margin: auto;
  margin-right: 0px;

  span {
    color: #ffffff;
    font-size: 12px;
    width: 135px;
    position: absolute;
    border-radius: 4px;
    // display: none;
    overflow: hidden;
  }
  // &:hover {
  //   span {
  //     display: block !important;
  //   }
  // }
}

.containerEmpty {
  width: 100%;
}

.menuItem {
  padding: 12px;
  cursor: pointer;
  background: #0a3637;
  white-space: nowrap;
  &:hover {
    background: transparentize($color: #0a3637, $amount: 0.1);
  }
  &:not(:first-child):not(.noBorder) {
    border-top: 1px solid #1e4a4b;
  }
}

.itemMenu {
  position: absolute;
  z-index: 11111111;
  margin-top: 25px;
  margin-left: -20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.blur {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: transparentize($color: #000000, $amount: 0.9);
  z-index: 1;
}

.textContainer {
  cursor: pointer;
}
