.modal {
  &.modal {
    max-width: 450px;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}
.message {
  margin-bottom: 24px;
  color: #000;
}

@media (max-width: 400px) {
  .actions {
    flex-direction: column;
  }
}
