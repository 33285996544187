.container {
  padding: 20px;
}

.item {
  padding: 20px;
}

.language {
  margin: 20px;
}

.row {
  > td {
    border: 1px solid black;
    padding: 10px;
  }
}
