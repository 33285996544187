.container {
  h4 {
    color: #0a3637;
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 500;
  }
  p {
    color: #1b1b1b;
    font-size: 14px;
  }
}

.fields {
  > div:not(:first-child) {
    margin-top: 20px;
  }
}

.input {
  width: 100%;
}

.questions {
  margin-top: 20px;
}
