.field {
  display: flex;
}

.title {
  margin-left: 10px;
  h4 {
    color: #0a3637;
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 500;
  }
  p {
    color: #1b1b1b;
    font-size: 14px;
  }
}
