.container {
  background-color: var(--color-sand);
  padding: 60px 32px 26px;
  min-height: 100vh;
}

.notFoundError {
  height: calc(100vh - 90px);
}

@media (max-width: 600px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
