@import "~sass/functions";

.container {
  width: 38px;
  height: 38px;
  border-radius: 200px;
  overflow: hidden;
  background-color: var(--color-pelorous);
  display: flex;
  align-items: center;
  text-align: center;
}

.initials {
  font-size: pxToRem(18px);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
  line-height: 0;
  font-weight: 300;
  text-transform: uppercase;
}

.initials {
  :global {
    span {
      width: 100%;
      height: 100%;
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagePreviewContainer {
  background-color: #46b0c6;
  > img {
    width: 50% !important;
    height: 50% !important;
  }
}

.preview {
  width: 50%;
  height: 50%;
  object-position: center;
  margin: auto;
  // background-color: var(--color-cloudy);
}

.imageContainer {
  background: transparent;
}
