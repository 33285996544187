.textButton {
  padding-left: 0px;
}

@media (max-width: 1023px) {
  .container {
    height: 100%;
  }
}

@media (max-width: 999px) {
  .submit {
    grid-template-columns: 1fr;
    margin-top: 24px;
    grid-row-gap: 30px;
    justify-content: center;
  }
  .submitButton {
    grid-row: 1;
    width: 100%;
    font-size: 16px;
  }

  .back {
    justify-self: center;
    font-size: 16px;
  }
}
