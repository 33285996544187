.orgCard {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.orgCardContent {
  display: flex;
  align-items: center;
}

.avatarContainer {
  margin-right: 24px;
}

.userAvatar {
  height: 120px;
  width: 120px;
  background-color: unset;
}

.orgNameContainer {
  display: flex;
  align-items: center;
}

.orgName {
  font-size: 24px;
  color: #0a3637;
  font-weight: 500;
}

.orgStatus {
  border-radius: 100px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  padding: 2px 8px;
  margin-left: 12px;
}

.orgStatusCurrent {
  background-color: var(--color-concrete);
}

.orgStatusNew {
  background-color: var(--color-capri);
}

.orgCounts {
  display: flex;
}

.orgCountContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}

.orgCount {
  font-size: 16px;
  color: #1b1b1b;
  margin-right: 30px;
}

.orgCountIcons {
  margin-right: 12px;
  color: #919fa1;
}

.manageOrgContainer {
  display: flex;
  align-items: center;
}

.manageOrg {
  margin-right: 10px;
  font-weight: 500;
  font-size: 15px;
}

.rightArrow {
  color: #319bc9;
}

.orgCardMeatBall {
  color: #dadbe3;
}
