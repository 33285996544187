.toastMessage {
  text-align: initial;
  .title {
    font-size: pxToRem(18px);
    display: flex;
    align-self: center;
    margin-bottom: 0;
  }
  .desc {
    font-size: pxToRem(16px);
    color: #004725;
    margin-left: 29px;
    margin-bottom: 0;
  }
  :global {
    .icon {
      margin-right: 12px;
    }
  }
}
