:root {
  --base-font-size: 14px;
  /* Black */
  --color-black-1: #24262a;
  --color-black-2: #1b1b1b;

  /* Red */
  --color-red-1: #d90000;
  --color-red-2: #ffe3e3;
  --color-red-3: #eb0000;
  --color-red-4: #2f0000;
  --color-red-5: #e84346;
  --color-red-6: #fef6f6;

  /* Gray */
  --color-gray-1: #9b9ca5;
  --color-gray-2: #dadbe3;
  --color-gray-3: #a0a1a9; // Custom Input placeholder color
  --color-gray-4: #8b8998;
  --color-gray-5: #f8f8fd;
  --color-gray-6: #979797;
  --color-gray-7: #edeef3;
  --color-gray-8: #919fa1;
  --color-gray-9: #ebebed;
  --color-gray-10: #d4e0e4;
  --color-gray-11: #aaadb9;

  /* Blue */
  --color-blue-1: #42b1c9;
  --color-blue-2: #1a7387;
  --color-blue-3: #d0ebf2;
  --color-blue-4: #0c96b4;
  --color-blue-5: #17869f;
  --color-blue-6: #f1fdff;
  --color-blue-7: #f8feff;
  --color-blue-9: #c3dce2;
  --color-blue-10: #46b0c6;
  --color-blue-11: #e6f0f3;
  --color-blue-12: #37474f;
  --color-blue-13: #319bc9;
  --color-blue-14: #2a8be6;
  --color-blue-15: #4dbbd1;

  /* Green */
  --color-green-1: #0a3637;
  --color-green-2: #e5f9e6;
  --color-green-3: #00c508;
  --color-green-4: #164041;
  --color-green-5: #3b5e5f;
  --color-green-6: #3d515b;
  --color-green-7: #dfffcc;
  --color-green-8: #00c366;
  --color-green-9: #00c637;

  /* Yellow */
  --color-yellow-1: #f1be48;
  --color-yellow-2: #fff1bd;
  --color-yellow-3: #ffc700;
  --color-yellow-4: #f6efd2;
  --color-yellow-5: #fff6d6;
  --color-yellow-6: #fef9e6;
  --color-yellow-7: #efdd9a;

  /* Orange */
  --color-orange-1: #ff5f00;

  /* Violet */
  --color-violet-1: #5f01d1;

  /* Pink */
  --color-pink-1: #e01e7b;
}
