@import "~sass/functions";

.container {
  max-width: 1150px;
  margin: 24px auto 0;
}

.header {
  margin-top: 10px;
}

.title {
  composes: headLine-2 from global;
  margin-bottom: 25px;
}

.form {
  display: flex;
  justify-content: space-between;
  width: inherit;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.panel {
  width: 564px;
  background: #ffffff;
  border-radius: 4px;
  padding: 23px 24px;
}

.panelTitle {
  composes: headLine-3 from global;
}

.formField {
  width: 100%;
  :global {
    .compMessage {
      font-weight: 500;
    }
  }
}

.formFieldEmail {
  :global {
    .suggestionContainer .loading {
      padding: 12px 16px;
    }
  }
}

.tableField {
  text-align: center;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.roleField {
  margin-bottom: 34px;
}

.suggestionItem {
  padding: 12px 16px;
  cursor: pointer;

  &.unverified {
    cursor: not-allowed;
    .detail:not(.email) {
      color: var(--color-asphalt);
    }
  }

  .detail {
    font-size: pxToRem(14px);
    margin-bottom: 0;
    color: var(--color-charcoal);
  }
  .email {
    color: var(--color-concrete);
  }

  &:nth-last-child(n + 2) {
    border-bottom: 1px solid var(--color-sand);
  }
}

.error {
  margin-top: 5px;
  font-weight: 500;
  font-size: 0.85714rem;
  color: var(--color-red-1) !important;
}
