.down {
  transform: rotate(90deg);
}

.icon {
  transition: transform 0.5s;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content {
  height: 0px;
  overflow: hidden;
  transition: height 2s;
}

.active {
  height: auto !important;
}
