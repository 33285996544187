.header {
  display: flex;
  align-items: flex-start;
  padding: 24px 24px 28px;
}

.titleContainer {
  margin-right: 24px;
  max-width: 615px;
  width: 100%;
}

.export {
  margin-left: auto;
}

.title {
  composes: headLine-4 from global;
}
.desc {
  margin-bottom: 0;
  width: 100%;
}
