@import "~sass/functions";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleContainer {
  margin-bottom: 7px;
  width: 100%;
}

.title {
  composes: headLine-4 from global;
  margin-bottom: 10px;
  color: var(--color-charcoal);
}

.tableButton {
  width: 167px !important;
}

.action {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 42px;
}

.table {
  width: 100%;
  margin-top: 24px;
  thead th:first-child {
    width: 110px;
  }
  tbody td {
    vertical-align: top;
  }
  tbody td:nth-child(2) {
    max-width: 150px;
  }
  &.overviewTable {
    margin-bottom: 16px;
    :global {
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        padding: 16px 24px;
      }
    }
  }
}

.note {
  margin-top: 10px;
}

.symptoms {
  width: 100%;
  display: inherit;
  // justify-content: left;
  // align-items: center;
}

.symptom {
  float: left;
  margin-right: 10px;
}

.pill {
  color: #0a3637;
  font-weight: 500;
  font-size: 13px;
  background: #f6fafb;
  border: 1px solid #edf6f9;
  // height: 29px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  padding: 5px 12px;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.testResult {
  font-weight: 500;
  font-size: 14px;
  color: #319bc9;
  cursor: pointer;
}

.viewAll {
  width: 100%;
  font-size: 16px;
  color: #299acb;
  cursor: pointer;
  padding: 0 24px 24px;
}

.nameActions {
  padding: 0 5px;

  &:nth-child(1) {
    padding-left: 0;
  }

  &:nth-last-child(n + 2) {
    border-right: 1px solid gray;
  }
}

.overviewPanel {
  padding: 0;
}

.panelHeader {
  padding: 24px;
}

.noSymptomsLogged {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noSymptomImage {
  max-width: 150px;
  min-height: 130px;
  margin-bottom: 24px;
}

.noSymptomsLoggedTitle {
  composes: headLine-3 from global;
  color: var(--color-concrete);
  margin-bottom: 24px;
}

.noSymptomsLogged.table > .noSymptomImage {
  max-width: 270px;
}

.warning {
  padding: 20px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
