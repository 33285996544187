.container {
  margin-bottom: 24px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  color: var(--color-apatite);
  font-weight: 500;
  font-size: 32px;
}
