.container {
  position: relative;
}

.lastLocation {
  position: absolute;
  bottom: 10px;
  left: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  padding: 12px 16px;
  min-width: 250px;
  background-color: #fff;
  border-radius: 4px;
}

.map {
  height: 100%;
}
