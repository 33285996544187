.buttonContainer {
  border: 1px solid var(--color-cloudy);
  border-radius: 8px;
  height: 150px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  padding-right: 24px;
  align-items: center;
}

.buttonContainer:first-child {
  margin-bottom: 24px;
}

.buttonContainer:hover {
  background-color: var(--color-water);
  transition: background-color 0.5s ease;
}

.image {
  height: 150px;
  width: 167px;
}

.textContent {
  margin-left: 10px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-apatite);
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  color: var(--color-charcoal);
  margin-bottom: 10px;
}

.note {
  font-size: 10px;
  font-style: italic;
  color: var(--color-asphalt);
}
