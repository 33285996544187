.container {
  max-width: 564px;
  text-align: center;
  margin: auto;

  h1 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 24px;
    color: #0a3637;
  }

  p {
    font-size: 16px;
  }
}

.content {
  background: #ffffff;
  text-align: left;
  padding: 30px;
}

.submit {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.submitButton {
  border-radius: 24px;
}

.items {
  display: inline;
}

.title {
  text-transform: uppercase;
  color: #aaadb9;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 16px;
}

.listItem {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  > h6 {
    color: #1b1b1b;
    font-size: 15px;
    font-weight: 500;
    // text-transform: capitalize;
    margin-bottom: 0px;
  }
  > p {
    margin-top: 0px;
    color: #7a7984;
    font-size: 12px;
  }
}

.select {
  :global {
    .ant-select-selection {
      padding: 5px;
      height: unset !important;
    }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  h1 {
    margin: 0;
    word-break: break-word;
  }
  .avatar {
    margin-right: 10px;
  }
}

@media (max-width: 1023px) {
  .headerContainer {
    flex-direction: column;
  }
}
