.input {
  width: 100%;
  > div > div {
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.submit {
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    > button {
      margin-left: 20px;
    }
  }
}

.submitSuccess {
  width: 100%;
}

.info {
  display: flex;
  justify-content: space-between;
}

.editButton {
  font-weight: 500;
  font-size: 14px;
  color: #319bc9;
  cursor: pointer;
}

.formLabel {
  color: #24262a;
  font-size: 12px;
  text-transform: capitalize;
}

.labelValue {
  > span {
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
  }
}

.error {
  margin-top: 5px;
  font-weight: 500;
  font-size: 0.85714rem;
  color: var(--color-red-1) !important;
}
