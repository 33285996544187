.title {
  composes: headLine-4 from global;
  font-weight: 500;
  font-size: 18px;
  color: #0a3637;
  margin-bottom: 0px;
}

.item:nth-last-child(n + 2) {
  margin-bottom: 24px;
}

.item:first-child {
  margin-top: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  color: #919fa1;
}
