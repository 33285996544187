@import "~sass/functions";

.modal {
  max-width: 448px;
  padding-left: 0;
  padding-right: 0;
  :global {
    .body {
      color: var(--color-black-2);
    }
  }
}

.includedMessage {
  background-color: var(--color-red-6);
  border: 1px solid var(--color-red-5);
  padding: 15px 14px;
  border-radius: 4px;
  font-size: pxToRem(12px);
  display: flex;
  align-items: center;
  margin-top: 24px;
  i {
    color: var(--color-red-5);
    font-size: pxToRem(20px);
    margin-right: 10px;
  }
}

.includedInLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  word-break: break-word;
  a {
    display: inline-flex;
    align-items: center;
    color: var(--color-blue-13);
  }
  a:nth-child(n + 2) {
    margin-top: 15px;
  }

  i {
    margin-left: 10px;
  }
}

.cancel {
  color: var(--color-gray-11);
}

.actionButton {
  margin-top: 0;
}
