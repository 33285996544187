@import "~sass/functions";

.container {
  max-width: 860px;
  margin: 24px auto 0;
}

.header {
  margin-top: 10px;
  width: 330px;
}

.title {
  composes: headLine-2 from global;
  margin-bottom: 15px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: inherit;
}

.panel {
  width: calc(100% - 330px);
}

.desc {
  padding-right: 24px;
  font-size: pxToRem(16px);
}

.subheader {
  text-transform: uppercase;
}
