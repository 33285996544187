.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #edeef3;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}

.container:first-child {
  border-top: none;
}

.name {
  color: var(--color-apatite);
  font-weight: 500;
  font-size: 16px;
}

.info {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.status {
  &.active {
    color: #00c366;
  }

  &.deactivated {
    color: var(--color-concrete);
  }
}

.circle {
  display: inline-block;
  height: 4px;
  width: 4px;
  background: var(--color-concrete);
  border-radius: 10px;
  margin: 0 8px;
}

.date {
  color: var(--color-asphalt);
}
