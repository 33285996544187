.link {
  text-decoration: none;
  color: var(--color-winter);
  font-size: inherit;
}
.icon {
  margin-right: 8px;
}
.iconOnly {
  margin-right: 0;
}

.notifMarker {
  height: 6px;
  width: 6px;
  border-radius: 20px;
  margin-left: 8px;
  background-color: var(--color-dracarys);
}

.loading {
  cursor: not-allowed;
  color: var(--color-sand);
}
