@import "~sass/functions";

.panel {
  padding: 24px;
}
.panelTitle {
  border-bottom: 1px solid #edeef3;
  margin-bottom: 16px;
}

.title {
  composes: headLine-4 from global;
}

.desc {
  font-size: pxToRem(14px);
  margin-bottom: 14px;
  color: var(--color-body-text);
}
