@import "~sass/functions";

.permissionTitle {
  font-size: pxToRem(18px);
  color: var(--color-apatite);
  margin-bottom: 0;
}

.permissionHeader {
  @extend .permissionTitle;
  font-size: pxToRem(15px);
}

.permissionFieldsTable {
  width: 100%;
  margin-top: 16px;
  thead:after {
    content: "";
    display: block;
    margin-bottom: 16px;
  }
  th:nth-child(n + 2),
  td:nth-child(n + 2) {
    text-align: center;
  }
  th {
    color: var(--color-concrete);
    font-size: pxToRem(12px);
    text-transform: uppercase;
    font-weight: 500;
  }
  td {
    &:after {
      content: "";
      display: block;
      margin-bottom: 12px;
    }
  }
  .permissionFieldEnd {
    border-bottom: 1px solid var(--color-cloudy);
    td:after {
      content: "";
      margin-bottom: 24px;
      display: block;
    }
  }
  .permissionFieldStart {
    td:before {
      content: "";
      margin-top: 24px;
      display: block;
    }
  }
}

.checkbox {
  :global {
    .ant-checkbox .ant-checkbox-inner {
      border-radius: 4px;
    }
  }
}
