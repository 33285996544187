.container {
  position: relative;
}

.suggestionContainer {
  z-index: 3;
  width: 100%;
  background-color: #fff;

  overflow-y: hidden;
  max-height: 200px;

  > div {
    border-radius: 0px 0px 4px 4px;
    padding-top: 10px;
    width: calc(100% - 9px);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: 4px;
    overflow-y: auto;
    max-height: 195px;
    margin-bottom: 10px;
  }
}
.suggestionItem {
  padding: 12px 16px;
  cursor: pointer;
}
