.tempContainer,
.tempStatusContainer {
  text-transform: capitalize;
}

.tempStatusContainer {
  font-weight: 500;
  color: var(--color-green-1);
}

.normal {
  color: #2a8be6;
}

.danger:not(.tempStatusContainer) {
  color: #fc2f35;
}

.normal.gradient {
  background: linear-gradient(180deg, #46b0c6 57.81%, #236092 149.75%);
}

.low.gradient {
  background: linear-gradient(180deg, #46b0c6 -56.87%, #236092 99.07%);
}

.higherThanNormal.gradient {
  background: linear-gradient(180deg, #fc2f35 -60.5%, #46b0c6 47.31%);
}

.high.gradient {
  background: linear-gradient(180deg, #fc2f35 -12.5%, #46b0c6 86.64%);
}

.veryHigh.gradient {
  background: linear-gradient(180deg, #fc2f35 6.65%, #46b0c6 142.43%);
}

.extremelyHigh.gradient {
  background: linear-gradient(180deg, #fc2f35 55.99%, #46b0c6 100%);
}

.tempContainer.gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
