.container {
  margin: 12px 0;
  cursor: pointer;
}

.panel:hover {
  background-color: var(--color-air);
}

.subContainer {
  display: flex;
  align-items: center;
}

.column {
  margin-right: 15px;
  width: 100%;
}

.nameContainer {
  max-width: 40%;
}

.name {
  color: var(--color-apatite);
  font-size: 18px;
  font-weight: 500;
  word-break: break-all;
}

.description {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.activeMemberContainer {
  max-width: 15%;
}

.tempCasesContainer {
  max-width: 15%;
}

.gatewayContainer {
  max-width: 18%;
}

.gateway {
  display: flex;
  align-items: center;
}

.gatewayName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.menuOptionContainer {
  max-width: 2%;

  &.hidden {
    visibility: hidden;
  }
}

.count {
  font-size: 24px;
  font-weight: 500;

  &.activeCount {
    color: var(--color-charcoal);
  }

  &.tempCount {
    color: var(--color-dracarys);
  }

  &.zero {
    color: var(--color-concrete);
  }
}

.label {
  color: var(--color-asphalt);
}

.circle {
  min-width: 8px;
  width: 8px;
  height: 8px;

  border-radius: 10px;
  margin-right: 8px;

  &.connected {
    background-color: var(--color-fern);
  }

  &.notConfigured {
    background-color: var(--color-dracarys);
  }

  &.disconnected {
    background-color: var(--color-concrete);
  }
}

.andMore {
  color: var(--color-winter);
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
}

.menuOption {
  min-width: 170px;
}
