@import "~sass/functions";

.header {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  border-bottom: 1px solid #edeef3;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.back {
  grid-row: 1 / span 2;
  color: var(--color-winter);
}

.title {
  margin-bottom: 4px;
  composes: headLine-4 from global;
}

.desc {
  margin-bottom: 0;
}

.title,
.desc {
  grid-column: 2;
}

.inviteRequestItem {
  display: grid;
  grid-template-columns: 40px 1fr 100px;
  align-items: center;
  grid-column-gap: 12px;
  &:nth-last-child(n + 2) {
    margin-bottom: 20px;
  }

  .orgLogo {
    background-color: unset;
  }
}

.orgName {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  composes: headLine-4 from global;
  color: var(--color-charcoal);
}

.orgPosition {
  composes: textSmall from global;
  margin-top: 4px;
  color: var(--color-asphalt);
}

.viewInvite {
  padding: 8px 12px;
}

.viewInviteModal {
  :global {
    .header {
      justify-content: flex-end;
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.viewInviteOrgName {
  composes: headLine-2 from global;
}

.viewInviteTitle {
  composes: textLarge from global;
}

.viewInviteDesc {
  text-align: center;
  font-size: pxToRem(12px);
}

.acceptInvite {
  border-radius: 24px;
}

.declineInviteModal {
  max-width: 450px;

  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .cancel {
    color: var(--color-concrete);
  }
}

.viewInviteAvatar {
  width: 100%;
  max-width: 80px;
  height: 80px;
  margin-bottom: 16px;
  background-color: unset;
}

.noInviteRequest {
  margin-bottom: 0;
  margin-top: 20px;
  text-align: center;
}
