.container {
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &.noData {
    min-height: 260px;
  }
}

.header,
.footer {
  padding-left: 25px;
  padding-right: 25px;
}

.header {
  padding-top: 25px;
}

.footer {
  padding-top: 10px;
  padding-bottom: 25px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}

.title {
  composes: headLine-4 from global;
  margin-bottom: 0;
  color: var(--color-charcoal);
  font-size: 16px;
}

.description {
  margin-bottom: 0;
  color: var(--color-apatite);
}

.table {
  :global {
    .ant-table-thead > tr:first-child > th:first-child,
    .ant-table-tbody > tr > td:first-child {
      padding-left: 25px;
    }
    .ant-table-thead > tr:first-child > th:last-child,
    .ant-table-tbody > tr > td:last-child {
      padding-right: 25px;
    }

    .ant-table-tbody > tr:last-child > td {
      border: none;
    }
  }
}

.viewAll {
  font-weight: 500;
}

.noDataContainer {
  padding: 25px;
}

.noDataTitle,
.noDataDescription {
  max-width: 230px;
}

.noDataTitle {
  composes: headLine-2 from global;
  color: var(--color-apatite);
  margin-bottom: 9px;
}

.noDataDescription {
  color: var(--color-asphalt);
}

.noDataImage {
  position: absolute;
  right: 0;
  width: initial;
  bottom: 0;
}
