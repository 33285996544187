.tableHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.titleContainer {
  margin-bottom: 7px;
  width: 100%;
}

.title {
  composes: headLine-4 from global;
  margin-bottom: 10px;
  color: var(--color-charcoal);
}

.tableButton {
  width: 167px !important;
}

.action {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 42px;
}
