.content {
  text-align: center;
  background: #ffffff;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -42px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px 30px 30px 30px;
  h1 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 24px;
    color: #0a3637;
  }
}

.description {
  font-size: 18px;
  color: #1b1b1b;
}

.modal.modal {
  max-width: 670px;
  :global {
    .ant-modal-content {
      max-width: 670px;
      background: #f6fafb;
    }
  }
}

.customModal.customModal {
  max-width: 670px;
  :global {
    .ant-modal-content {
      max-width: 670px;
    }
  }
}
