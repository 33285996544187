@import "~sass/functions";
@import "~sass/mixins";

.container {
  margin-bottom: 30px;
}

.header,
.questionContainer {
  display: grid;
  grid-template-columns: 1fr 80px;
}

.header {
  margin-top: 30px;
}

.columnName {
  color: var(--color-gray-8);
  text-transform: uppercase;
  font-size: pxToRem(12px);
  font-weight: 500;
  margin: 0;
  &:nth-child(2) {
    text-align: center;
  }
}

.questionContainer {
  margin-top: 20px;
  padding-bottom: 16px;
  &:nth-last-child(n + 2) {
    border-bottom: 1px solid #edeef3;
  }
}

.question {
  color: var(--color-green-1);
  margin: 0;
  cursor: pointer;
  word-break: break-word;
}

.autoAdd {
  justify-self: center;
}

.pill {
  border-radius: 100px;
  background-color: var(--color-blue-14);
  color: #fff;
  font-size: pxToRem(13px);
  margin-left: 10px;
  display: none;
  pointer-events: none;
}
.pill.show {
  display: inline-block;
  @include animation(pillTransition, 1.5s);
  animation-fill-mode: forwards;
  animation-delay: 3s;
}

@keyframes pillTransition {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
