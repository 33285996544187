.pastOganizations {
  border-top: 1px solid #edeef3;
  padding-top: 16px;
  margin-top: 16px;
}

.orgCountTitle {
  margin: 0;
  color: var(--color-charcoal);
  composes: textMedium from global;
}

.orgCount {
  color: var(--color-concrete);
  font-weight: inherit;
}

.pastDesc {
  composes: textSmall from global;
  color: var(--color-asphalt);
  margin-bottom: 9px;
}

.item {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  .avatar {
    background-color: unset;
  }
}

.details {
  margin-left: 12px;
  margin-right: auto;
}

.orgPosition,
.orgName,
.positionDetails {
  margin-bottom: 0;
}

.orgName {
  composes: buttonText from global;
}

.positionDetails {
  composes: textSmall from global;
  display: flex;
  align-items: center;
}

.dateJoined {
  color: var(--color-asphalt);
}
