.selectedItem {
  background-color: var(--color-blue-3);
  font-weight: 500;
  color: var(--color-green-1);
}

:global {
  .ant-dropdown-menu {
    padding: 0;
  }
  .ant-dropdown-menu-item.selectedItem:hover {
    background-color: var(--color-blue-3);
  }
  .ant-dropdown-menu-item:not(.selectedItem):hover {
    background-color: transparent;
  }
}

.meatBalls {
  background-color: var(--color-green-1);
  :global {
    .ant-dropdown-menu-item {
      color: #fff;
    }
  }
}

.options {
  font-weight: 500;
  font-size: 14px;
  color: #319bc9;
  text-align: center;
  padding: 15px 0px;
}
