:root {
  // Product Color
  --color-pelorous: #46b0c6;
  --color-anzac: #e9bc31;

  // Neutral Colors
  --color-concrete: #aaadb9;
  --color-asphalt: #7a7984;
  --color-apatite: #0a3637;
  --color-charcoal: #1b1b1b;
  --color-air: #f6fafb;
  --color-water: #edf6f9;
  --color-sand: #e6f0f3;
  --color-smoke: #dbe9ed;
  --color-cloudy: #d4e0e4;

  // Secondary Colors
  --color-pond: #42b1e1;
  --color-winter: #319bc9;
  --color-deep: #0f729f;
  --color-gladiola: #f75658;
  --color-salsa: #e84346;
  --color-blazer: #d5373b;
  --color-highlight: #fef9e6;
  --color-buff: #f8edc1;
  --color-custard: #efdd9a;

  // Accent Color
  --color-fern: #00c366;
  --color-dracarys: #fc2f35;
  --color-capri: #2a8be6;

  // New Colors
  --color-blaze-orange: #ff6b00;
  --color-body-text: #24262a;
  --color-mercury: #919fa1;
}
