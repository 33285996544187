@import "~sass/functions";

.container {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  margin-bottom: 16px;
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: var(--color-winter);
  font-size: pxToRem(16px);
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.center {
  margin-bottom: 4px;
}

.zoomControl {
  &:nth-last-child(1) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:nth-last-child(2) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:nth-last-child(n + 2) {
    margin-bottom: 2px;
  }
}
