.container {
  width: 100%;
  padding: 16px 0;

  h4 {
    color: #aaadb9;
    font-weight: 500;
    font-size: 12px;

    text-transform: uppercase;
    margin: 24px;
  }
}

.menu {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border-left: 4px solid transparent;
  color: #000000;
  &.active {
    background: #f6fafb;
    border-left-color: #46b0c6;
  }
}

.active {
  color: #46b0c6;
}

.menuText {
  font-weight: 500;
  font-size: 16px;
}

.menuIcon {
  color: #46b0c6;
  font-size: 20px;
  margin-right: 20px;

  width: 22px;
  text-align: center;
}
