.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  > button {
    margin-left: 20px;
  }
}

.showPasswordIcon {
  font-size: 30px;
  color: #319bc9;
}

.passwordStrength {
  margin-top: 20px;
  margin-bottom: -30px;
}
