@import "~sass/functions";

.menu {
  margin-left: 50px;
  display: flex;
  position: relative;
}
//
.link {
  font-size: pxToRem(18px);
  display: inline-flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  padding: 0px 30px;
  &:hover,
  &.active {
    color: rgba(255, 255, 255, 1);
  }

  &.active {
    border-bottom: 3px solid var(--color-green-1);
  }

  &:hover {
    background-color: var(--color-blue-15);
  }

  :global {
    .icon {
      display: inline-block;
      width: 18px;
    }
  }

  // &:last-child {
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     right: inherit;
  //     bottom: 0;
  //     width: inherit;
  //     height: 3px;
  //     background: var(--color-green-1);
  //     pointer-events: none;
  //     transition: all 0.5s ease;
  //   }
  // }
}

.link:nth-child(1).active ~ .link:last-child:after {
  left: 0;
  width: 140px;
}

.link:nth-child(2).active ~ .link:last-child:after {
  left: 140px;
  width: 110px;
}

.link:nth-child(3).active ~ .link:last-child:after {
  left: 250px;
  width: 140px;
}

.link:nth-child(4).active:after {
  left: 390px;
  width: 136px;
}

@media (max-width: 1024px) {
  .menu {
    display: none;
  }
}
