.content {
  display: flex;
  justify-content: space-between;
  width: inherit;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.panel {
  width: 100%;
}

.panelTitle {
  font-weight: 500;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin-bottom: 0px;
  }
  p {
    font-size: 14px;
    color: #24262a;
  }
  border-bottom: 1px solid #edeef3;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.info {
  display: flex;
  justify-content: space-between;
}

.item {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  justify-content: space-between;
}

.avatar {
  height: 52px;
  width: 52px;
  margin-right: 20px;
}

.menu {
  font-size: 20px;
  color: #d4e0e4;
  width: 20px;

  span {
    color: #ffffff;
    font-size: 12px;
    width: 150px;
    position: absolute;
    border-radius: 4px;
    display: none;
    overflow: hidden;
  }
  &:hover {
    span {
      display: block !important;
    }
  }
}

.menuItem {
  padding: 20px;
  cursor: pointer;
  background: #0a3637;
  &:hover {
    background: transparentize($color: #0a3637, $amount: 0.1);
  }
  &:not(:last-child) {
    border-bottom: 1px solid #1e4a4b;
  }
}

// .itemMenu {
//   display: none;
// }

.name {
  width: calc(100% - 92px);
  max-width: 100%;
  text-overflow: ellipsis;
  word-break: break-all;
  h5 {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 2px;
    color: #0a3637;
  }
  p {
    color: #7a7984;
    font-size: 12px;
    margin-bottom: 0px;
  }
}

.listTitle {
  color: #aaadb9;
  font-weight: 500;
  font-size: 12px;

  text-transform: uppercase;
  border-bottom: 1px solid #dadbe3;
  padding-bottom: 8px;
  margin-top: 30px;
}

.pendingItems {
  margin-top: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid #dadbe3;
    padding-bottom: 15px;
  }
}

.removeManager {
  color: #000000;
  font-size: 16px;
}

.submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  > button {
    margin-left: 20px;
  }
}
