@import "~sass/mixins";
@import "~sass/functions";

.formFieldOptionsContainer {
  display: flex;
  flex-direction: column;
}

.formFieldsOptions {
  margin: 0 0 15px;
  &.scrollable {
    max-height: 240px;
    overflow-y: auto;
    margin-top: 10px;
  }
  & > *:nth-last-child(n + 2) {
    margin-bottom: 10px;
  }
  &:not(:focus-within) {
    .dragItemError {
      :global {
        .dragIcon {
          margin-bottom: 20px;
        }
      }
    }
    .formFieldOption.error {
      .dummyField,
      .optionFieldRemove {
        margin-bottom: 20px;
      }
    }
    :global {
      .optionFieldLabel.error {
        .container {
          border-bottom: 1px solid var(--color-red-5);
        }
        .compMessage {
          display: block;
        }
      }
    }
  }
}

.formFieldOption {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  -webkit-user-drag: element;
  width: 88%;
}

.title {
  margin: 0;
  font-size: pxToRem(12px);
  color: var(--color-black-2);
}

.addOption {
  font-size: pxToRem(12px);
  color: var(--color-blue-1) !important;
  font-weight: 500;
  align-self: flex-start;
  padding: 0;
}

.title,
.addOption {
  margin-left: 24px;
  margin-right: 24px;
}
.optionFieldRemove {
  color: var(--color-gray-2);
  font-size: 12px;
}

.lastOptionField {
  cursor: not-allowed;
}

.optionFieldLabel {
  width: 100%;
  margin: 0 10px;
  :global {
    .container {
      border: 0;
      border-radius: 0;
      transition: none;
    }
    .container:focus-within {
      border-bottom: 1px solid var(--color-blue-1);
    }
    input {
      min-height: 30px;
      padding: 0;
    }
  }
}

.optionFieldLabel.error {
  :global {
    .compMessage {
      display: none;
    }
  }
}

.formFieldOptionTitle {
  font-size: pxToRem(12px);
  font-weight: 500;
  color: var(--color-black-1);
  margin-bottom: 15px;
}

.optionCount {
  display: inline-block;
  width: 25px;
}

.dummyField {
  pointer-events: none;
  margin-right: 0;
  :global {
    .ant-radio-disabled .ant-radio-inner {
      background-color: #fff;
    }
  }
}

.drag {
  position: absolute;
  left: 5px;
  cursor: move;
}

.draggableItems {
  :global {
    .draggableItem {
      padding: 8px 0;
      border: none;
    }
  }
}
