@import "~sass/functions";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}

.action {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 42px;
}

.note {
  margin-top: 10px;
}

.symptoms {
  width: 100%;
  display: inherit;
  // justify-content: left;
  // align-items: center;
}

.symptom {
  float: left;
  margin-right: 10px;
}

.pill {
  color: #0a3637;
  font-weight: 500;
  font-size: 13px;
  background: #f6fafb;
  border: 1px solid #edf6f9;
  // height: 29px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  padding: 5px 12px;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.testResult {
  font-weight: 500;
  font-size: 14px;
  color: #319bc9;
  cursor: pointer;
}

.viewAll {
  width: 100%;
  font-size: 16px;
  color: #299acb;
  cursor: pointer;
  padding: 0 24px 24px;
}

.nameActions {
  padding: 0 5px;

  &:nth-child(1) {
    padding-left: 0;
  }

  &:nth-last-child(n + 2) {
    border-right: 1px solid gray;
  }
}

.overviewPanel {
  padding: 0;
}

.panelHeader {
  padding: 24px;
}

.warning {
  padding: 20px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
