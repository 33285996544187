@mixin transition($props, $duration: 0.3s, $ease: ease) {
  transition: #{$props} #{$ease} #{$duration};
}

@mixin animation($name, $duration: 0.3s) {
  animation: #{$name} #{$duration};
}

@mixin unSelectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-style: none;
}

@mixin userSelect($value: none) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin filter($value) {
  -webkit-filter: $value;
  -moz-filter: $value;
  -o-filter: $value;
  filter: $value;
}
