@import "~sass/functions";

.modal {
  max-width: 450px;
  :global {
    .header {
      margin-bottom: 24px;
      color: var(--color-body-text);
    }
  }
}

.title {
  margin-bottom: 4px;
  color: var(--color-apatite);
  font-size: pxToRem(15px);
}

.desc:not(:last-child) {
  color: var(--color-charcoal);
  margin-bottom: 24px;
}

.desc:last-child {
  margin-bottom: 0;
}
