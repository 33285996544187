@import "~sass/functions";

.container {
  display: grid;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.headerTitle {
  composes: headLine-2 from global;
  margin-bottom: 0;
}

.panel {
  &:nth-last-child(n + 2) {
    margin-bottom: 24px;
  }
}

.panelTitle {
  composes: headLine-4 from global;
  border-bottom: 1px solid #edeef3;
  padding-bottom: 16px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.avatarContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.avatar {
  margin-right: 16px;
  width: 80px;
  height: 80px;
  :global {
    .initials {
      font-size: pxToRem(28px);
    }
  }
}
.avatarActions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 250px;
}

.uploadGuide {
  composes: textSmall from global;
  color: #9b9ca5;
  margin-top: 8px;
  margin-bottom: 0;
}

.uploadContainer {
  :global {
    .uploadField .ant-upload {
      align-items: flex-start;
    }
  }
}

.field {
  width: 100%;
  &:nth-last-child(n + 2) {
    margin-bottom: 24px;
  }
}

.inputMobileNumber {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-column-gap: 10px;

  :global {
    .selectContainer {
      min-width: initial;
    }
  }
}

.inputMobileNumberLabel {
  composes: textSmall from global;
  grid-column: 1 / -1;
  cursor: inherit;
}

.message {
  border-radius: 4px;
  justify-self: flex-start;
  margin-bottom: 14px;
  :global {
    .message {
      display: flex;
      align-items: center;
    }
  }
}

.warningIcon {
  margin-right: 10px;
}
