.menuTab {
  margin-top: 20px;
  grid-column: 1;
  grid-row: 1;
  margin-bottom: 24px;
  display: flex;
  > .link {
    > div {
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
    }

    margin-right: 24px;
  }
}

.activeTab {
  color: #0a3637 !important;
}

.forbidden {
  color: #919fa1 !important;
  cursor: auto;
}
.icon {
  margin-right: 10px;
}

.private {
  display: flex;
  span {
    display: flex;
  }
}
