.title {
  color: var(--color-apatite);
  font-weight: 500;
  font-size: 18px;
}

.createFormContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .image {
    margin-bottom: 30px;
  }

  > .text {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }
}

.forms {
  margin-top: 24px;
}

.form {
  &:nth-child(even) {
    background-color: black;
  }
  &:nth-child(odd) {
    background-color: red;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 30px;

  &.start {
    align-items: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.separator {
    padding-bottom: 15px;
    border-bottom: 1px solid #edeef3;
  }
}

.titleInfoContainer {
  padding-right: 35px;
}
