@import "~sass/functions";

.modal {
  max-width: 450px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.remove {
  margin-right: 10px;
}

.message {
  font-size: pxToRem(16px);
  color: #000;
  margin-bottom: 24px;
}
