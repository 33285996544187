.duo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 48%;
    display: flex;
  }
}

.error {
  &:not(:focus) {
    border-color: var(--color-red-1);
  }
}

.inputField {
  width: 100%;
  margin-bottom: 10px;
}

.resendButton {
  margin-top: 24px;
}
