.filterOption {
  :global {
    .label {
      color: var(--color-charcoal);
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}

.memberStatus {
  display: flex;
  align-items: center;
  :global {
    .icon {
      margin-left: 5px;
    }
  }
}
