.container {
  width: auto;
}

.modal {
  width: 800px;
  max-width: 800px !important;
}

.header {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  padding-top: 18px;
}

.users {
  display: flex;
  align-items: center;
  margin: auto;
}

.usersContainer {
  text-align: center;
  display: flex;
  align-items: center;
}

.avatar {
  margin: auto;
  height: 64px;
  width: 64px;
}

.and {
  margin: 24px;
  color: #aaadb9;
  font-size: 14px;
}

.userName {
  color: #24262a;
  font-size: 13px;
  font-weight: 500;
  margin-top: 20px;
}

.content {
  margin-top: 20px;
}

.left {
  margin-right: 40px;

  cursor: default !important;
}

.right {
  margin-left: 40px;
  cursor: default !important;
}

.active {
  color: #319bc9;
  cursor: pointer;
}

.export {
  position: absolute;
  top: 0;
  padding: 0;
}

.contactUsers {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
