@import "~sass/functions";
.container {
  background-color: var(--color-smoke);
  border-radius: 4px;
  margin: auto;
  margin-bottom: 24px;
}

.searchBar {
  display: flex;
  align-items: center;
}

.filtersContainer {
  padding: 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto [last-line];
  grid-gap: 15px 25px;
  background-color: var(--color-smoke);
  min-height: 175px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: none;
  align-content: flex-start;
}

.filtersContainer.active {
  display: grid;
}

.filter {
  padding: 13px 18px;
  color: var(--color-winter);
  font-weight: 500;
  cursor: pointer;
  > .filterIco {
    margin-right: 10px;
  }
  > .chevIco {
    font-size: pxToRem(9px);
    margin-left: 10px;
  }
}

.actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  grid-column: 1 / -1;
  grid-row: last-line;
}

.searchForm {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.clearInput {
  composes: textSmall from global;
  position: absolute;
  right: 35px;
  max-width: 75px;
  max-height: 24px;
  text-transform: uppercase;
  padding: 4px 12px;
  border-radius: 24px;
}

.clearInputIcon {
  font-size: pxToRem(8px);
}

.clearInput,
.clearInput:hover {
  background-color: var(--color-sand);
  color: var(--color-winter);
}

.searchInput {
  width: 100%;
  :global {
    .container,
    .ant-input {
      border-radius: 0;
      border-top-right-radius: 4px;
    }
    .ant-input-affix-wrapper .ant-input {
      padding-right: 140px;
    }
  }
}

.applyFilters {
  padding: 8px 12px;
}

.clearFilters {
  padding: 6px 12px;
  margin-right: 10px;
}
