@import "~sass/mixins";
@import "~sass/functions";

.field {
  display: inline-flex;
  align-items: flex-start;
}

.label {
  font-size: pxToRem(12px);
  margin-top: 3px;
  margin-left: 7px;
  line-height: 16px;
  width: 100%;
}

.active {
  background-color: var(--color-blue-1);
  color: #fff;
  border: 1px solid var(--color-blue-1);
}

.check {
  font-size: pxToRem(9.8px);
  position: absolute;
}

:global {
  .ant-checkbox {
    &:hover .ant-checkbox-inner {
      border-color: var(--color-blue-1);
      &:disabled {
        cursor: not-allowed;
        background-color: var(--color-gray-2);
        border-color: var(--color-gray-2);
      }
    }
    &:after {
      display: none;
    }
  }

  .ant-checkbox-checked.ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: var(--color-cloudy);
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.19);
      &:after {
        border-color: #fff;
      }
    }
  }

  .ant-checkbox-checked:not(.ant-checkbox-disabled) {
    .ant-checkbox-inner {
      background-color: var(--color-blue-1);
      border-color: var(--color-blue-1);
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--color-blue-1);
  }

  .ant-checkbox-inner {
    @include transition(border-color);
    background-color: #fff;
    padding: 3px;
    border-radius: 2px;
    box-sizing: border-box;
    min-width: 18px;
    min-height: 18px;
    border: 1px solid var(--color-gray-1);
  }
}
