.learnMore {
  margin-left: 3px;
  color: #319bc9;
  font-weight: 500;
  cursor: pointer;
}

.actionButton {
  display: flex;
  align-items: center;
}
