@import "~icons/style";
@import "~antd/dist/antd";
@import "./variables";
@import "./colors";
@import "./typography";
@import "./component";
@import "./helpers";

html {
  scroll-behavior: smooth;
}

html,
body,
:root {
  font-size: var(--base-font-size);
  color: var(--color-body-text);
}

* {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  outline: 0;
}

div::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
div::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
div::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
div::-webkit-scrollbar-button {
  display: none;
}
