@import "~sass/functions";

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  font-weight: 500;
}

.headLine-1 {
  font-size: pxToRem(36px);
  line-height: 1.3;
  font-weight: 500;
  color: var(--color-apatite);
}

.headLine-2 {
  @extend .headLine-1;
  font-size: pxToRem(24px);
}

.headLine-3 {
  @extend .headLine-1;
  font-size: pxToRem(20px);
}

.headLine-4 {
  @extend .headLine-1;
  font-size: pxToRem(18px);
  line-height: initial;
}

.textLarge {
  @extend .headLine-2;
  font-weight: normal;
}

.textMedium {
  font-size: pxToRem(16px);
  font-weight: 500;
}

.textSmall {
  font-size: pxToRem(12px);
  font-weight: normal;
}

.buttonText {
  font-weight: 500;
  font-size: 15px;
}

.bodyText {
  @extend .textMedium;
  font-weight: normal;
}
