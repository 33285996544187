.pill {
  background-color: var(--color-water);
  border: 1px solid var(--color-cloudy);
  border-radius: 4px;
  padding: 4px 12px;
  display: inline-flex;
  align-items: center;
  color: var(--color-charcoal);
  &:hover {
    background-color: var(--color-smoke);
    transition: background-color 0.5s ease;
  }
}

.pill.error {
  background-color: #ffffff;
  border: 1px solid var(--color-dracarys);
  color: var(--color-dracarys);
  &:hover {
    background-color: var(--color-water) !important;
    transition: background-color 0.5s ease;
  }
}

.icon {
  color: var(--color-asphalt);
  font-size: 12px;
  margin-left: 8px;
  cursor: pointer;
}
