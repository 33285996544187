.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
}

.title,
.desc {
  width: 380px;
}

.title {
  composes: headLine-2 from global;
  margin-bottom: 8px;
}

.desc {
  margin-bottom: 25px;
}

.newPassword,
.confirmPassword {
  :global {
    .passwordSuffix {
      color: var(--color-winter);
    }
  }
}

.newPassword {
  margin-bottom: 13px;
}

.confirmPassword {
  margin-bottom: 28px;
}

.email,
.sendResetLink {
  margin-bottom: 28px;
}

.resetPassword {
  align-self: flex-start;
  margin-bottom: 28px;
}

.loginNow {
  composes: buttonText from global;
}

.passwordStrength {
  margin-bottom: 20px;
}

.message {
  margin-bottom: 16px;
}
.backToLogin {
  align-self: flex-start;
}
