.container {
  display: flex;
  justify-content: flex-end;
  :global {
    .ant-select-selection {
      min-height: initial;
    }
    .ant-select-selection__rendered {
      margin-right: 35px;
    }
  }
}
