.panelTitle {
  font-weight: 500;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin-bottom: 0px;
  }
  &:not(.noBorder) {
    border-bottom: 1px solid #edeef3;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.select {
  width: 100%;
}
