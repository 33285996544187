.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  margin-top: 24px;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.upload {
  justify-self: flex-start;
  margin-right: 15px;
}

.uploadDesc {
  composes: textSmall from global;
  color: var(--color-concrete);
  margin: 0;
}
