.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background-color: #fff;
}

.image {
  height: 135px;
  max-width: 150px;
  margin-bottom: 10px;
}

.message {
  margin-left: 24px;
  max-width: 200px;
}
.title {
  composes: mediumText from global;
  margin-bottom: 9px;
}

.desc {
  color: var(--color-asphalt);
  margin: 0;
}
