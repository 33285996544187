@import "~sass/functions";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  composes: headLine-3 from global;
  margin: 0;
}

.close {
  font-size: pxToRem(12px);
  color: var(--color-gray-2);
}

:global {
  .ant-modal {
    transform-origin: center center !important;
    max-width: 520px;
    width: 100% !important;
    z-index: 1111;
  }
  .ant-modal-body {
    position: relative;
  }
}

@media (max-width: 400px) {
  :global {
    .ant-modal {
      max-width: 350px;
      min-width: initial;
    }
  }
}
