@import "~sass/mixins";
.uploadField {
  :global {
    .ant-upload {
      margin: 0;
      border: none;
      background-color: transparent;
    }

    .ant-upload img {
      height: 100%;
      object-fit: cover;
    }

    .ant-upload > .ant-upload {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .ant-upload.ant-upload-select-picture-card {
      height: initial;
    }

    .ant-upload-select-picture-card > .ant-upload {
      padding: 0;
    }
  }
}
