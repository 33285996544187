@import "~sass/functions";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  composes: headLine-4 from global;
  align-self: flex-start;
}

.image {
  max-width: 185px;
  min-height: 160px;
  margin-bottom: 16px;
}

.title {
  composes: headLine-3 from global;
  color: var(--color-apatite);
}

.desc {
  font-size: pxToRem(16px);
  text-align: center;
  margin: 0;
}
