.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.generateButton {
  margin-left: auto;
  margin-right: 8px;
}

.downloadButton1 {
  margin-left: auto;
  margin-right: 8px;
}

.downloadButton2 {
  margin-right: 8px;
}

.title {
  composes: headLine-1 from global;
  margin-bottom: 0;
  margin-right: 20px;
}

.addNew {
  border-radius: 100px;
}
