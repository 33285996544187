@import "~sass/functions";

.success {
  color: #ffffff;
  > a {
    margin-left: 10px;
    color: #ffffff;
    text-decoration: underline;
  }
}

.errorMessage {
  font-size: 10px;
  font-weight: 500;
  color: var(--color-red-1);
}
