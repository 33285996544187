@import "~sass/functions";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0px;
}

.titleContainer {
  margin-top: 20px;
  margin-bottom: 7px;
  width: 100%;
}

.title {
  composes: headLine-4 from global;
  margin-bottom: 10px;
  color: var(--color-charcoal);
}

.accessoryImage {
  max-width: 200px;
  // min-height: 145px;
  margin-bottom: 35px;

  // &.noAccessory {
  //   margin-bottom: 25px;
  // }
}

.action {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 42px;
  margin-bottom: 24px;

  &.noAccessory {
    margin-bottom: 0;
  }
}

.details {
  align-self: stretch;
}

.noAccessoryTitle {
  composes: headLine-3 from global;
  color: var(--color-concrete);
  margin-bottom: 20px;
}

.lastSynced {
  display: flex;
  align-items: center;
}

.warningIcon {
  font-size: pxToRem(18px);
  margin-right: 17px;
  color: var(--color-dracarys);
}

.errorImage {
  opacity: 0.4;
}

.listContainer {
  margin-top: 20px;
  width: 100%;
  text-align: left;
}

.list {
  display: flex;
  align-items: left;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px;
  align-items: center;
  &:not(:first-child) {
    border-top: 1px solid #dbe9ed;
  }
  h4 {
    color: #0a3637;
    font-size: 14px;
    font-weight: 500;
  }
  p {
    color: #9b9ca5;
    font-size: 12px;
    margin-bottom: 0px;
  }
}

.remove {
  color: #dadbe3;
  font-size: 12px;
}

.icon {
  margin-right: 20px;
}
