.droppable {
  width: 100%;
}

.container {
  :global {
    div[data-rbd-droppable-id="droppable"] {
      width: 100% !important;
    }
  }
}

.draggableItem {
  display: flex;
  padding: 20px 0px;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #edeef3;
  }
}

.dragIcon {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 18px;
  color: #b9cace;
}
