.container {
  max-width: 1150px;
  margin: 24px auto 0;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 24px;
}

.leftCol {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 32px;
}

.intro {
  display: flex;
  flex-direction: column;
}

.orgYouManageContainer {
  display: flex;
}

.settings {
  font-size: 20px;
  color: #aaadb9;
  cursor: pointer;
}

.header {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #0a3637;
}

.description {
  font-size: 16px;
  color: #1b1b1b;
  margin-bottom: 16px;
}

.viewOrganization {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #aaadb9;
}

.viewMyActiveOrg {
  font-size: 14px;
  margin-left: 5px;
}

.rightCol {
  background-color: unset;
}

.createOrg {
  padding: 24px;
  background-color: #f6fafb;
  border-radius: 4px;
  margin-bottom: 24px;
}

.createWorryFree {
  font-size: 18px;
  color: #0a3637;
  font-weight: 500;
  margin-bottom: 8px;
}

.wantToCreate {
  font-size: 16px;
  color: #0a3637;
}

.pouchpassLink {
  font-size: 16px;
  margin-left: 4px;
  margin-right: 4px;
}
