.container {
  width: 100%;
  max-width: 564px;
  text-align: left;
  margin: auto;

  h1 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 24px;
    color: #0a3637;
  }

  p {
    font-size: 16px;
  }
}

.description {
  font-size: 12px;
  color: #7a7984;
}

.link {
  font-size: 12px;
  color: #46b0c6;
  cursor: pointer;
}

.content {
  background: #ffffff;
  text-align: left;
  padding: 24px;
}

.input {
  width: 100%;
  > div {
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.mobileNumber {
  min-width: unset !important;
}

.inputMobileNumber {
  display: flex;
  justify-content: space-between;
  > div:first-child {
    width: 100px;
  }
  > div:nth-child(2) {
    width: calc(100% - 110px);
  }
  > div > div {
    width: 100%;
  }
}

.label {
  color: #919fa1;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 20px;
}
.value {
  color: #1b1b1b;
  font-size: 14px;
}

.textButton {
  padding-left: 0px;
}

@media (max-width: 1023px) {
  .container {
    height: 100%;
  }
}
