.container {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
  height: 100vh;
}

.gradient {
  background: linear-gradient(180deg, #fc2f35 -12.5%, #46b0c6 86.64%), #46b0c6;
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: minmax(300px, 2fr) minmax(300px, 1fr);
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
  }
  .gradient {
    display: none;
  }
}
