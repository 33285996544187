@import "~sass/functions";

.container {
  max-width: 1000px;
  margin: auto;
  padding: 35px;
}

.title {
  font-weight: 500;
  font-size: 36px;
  color: var(--color-apatite);
}

.backLinkContainer {
  display: flex;
  align-items: center;
  color: #919fa1;
  margin-bottom: 30px;
  cursor: pointer;

  > .icon {
    font-size: 9px;
  }

  cursor: pointer;
}

.backLink {
  margin-left: 11px;
}

.row {
  margin-bottom: 24px;
  display: flex;

  > .left {
    max-width: 330px;
    margin-right: 24px;
  }
}

.panelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.panelTitle {
  font-size: 18px;
  color: var(--color-apatite);
  font-weight: 500;
}

.leftContainer {
  width: 330px;
  font-size: pxToRem(16px);
}

.questionsContainer {
  display: flex;
}

.rightContainer {
  width: calc(100% - 330px);
  padding-left: 24px;
}

.panelContainer {
  margin-bottom: 24px;
}

.down {
  transform: rotate(90deg);
}

.icon {
  transition: transform 0.5s;
}
