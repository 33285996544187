.container {
  max-width: 860px;
  margin: 24px auto 0;
}

.header {
  margin-top: 10px;
  width: 330px;
  h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  p {
    width: 300px;
    font-size: 16px;
  }
}

.title {
  composes: headLine-2 from global;
  margin-bottom: 25px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: inherit;
}

.panel {
  width: calc(100% - 330px);
}
