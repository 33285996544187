.container {
  margin-top: 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  font-size: 18px;
  margin-bottom: 2px;
  color: var(--color-apatite);
}

.down {
  transform: rotate(90deg);
}

.icon {
  transition: transform 0.5s;
}

.alertContainer {
  margin: 24px 0;
}

.alertField {
  flex-grow: 1;
}

.alertRow {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.removeAlert {
  cursor: pointer;
  color: #919fa1;
  font-size: 12px;
  margin-left: 14px;
}

.addAnother {
  color: var(--color-winter);
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}
