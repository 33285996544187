@import "sass/functions";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
}

.title {
  composes: headLine-3 from global;
}

.image {
  max-width: 300px;
  height: 50px;
  margin-bottom: 48px;
  &.error {
    margin-bottom: 28px;
  }
}

.message {
  margin-bottom: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
}

.input {
  &:nth-last-child(n + 2) {
    margin-bottom: 15px;
  }
  :global {
    .passwordSuffix {
      color: var(--color-winter);
    }
  }
}

.login {
  align-self: flex-start;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: center;
}

.rememberMe {
  :global {
    .checkboxLabel {
      color: var(--color-concrete);
    }
    .ant-checkbox-inner {
      min-width: 16px;
      min-height: 16px;
    }
  }
}

.forgotPassword {
  composes: buttonText from global;
}

.instruction {
  color: var(--color-green-1);
  font-size: pxToRem(16px);
}

@media (max-width: 767px) {
  .form {
    max-width: 100%;
  }
  .login {
    width: 100%;
  }
}
