.container {
  max-width: 565px;
  width: 100%;
}

.title {
  composes: headLine-2 from global;
  margin-bottom: 8px;
}

.desc {
  composes: bodyText from global;
  margin-bottom: 24px;
}

.formContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 24px;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
}

.fullColumn {
  grid-column: 1 / -1;
}

@media (max-width: 1023px) {
  .container {
    height: 100%;
  }
}

@media (max-width: 999px) {
  .formContainer {
    grid-template-columns: 1fr;
  }
}
