.container {
  margin: 10px;
  text-align: center;
}

.bullets {
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;
  width: fit-content;
}

.bullet {
  width: 8px;
  height: 8px;
  background: #d4e0e4;
  border-radius: 100%;
  margin: auto;
  margin-right: 20px;
  &:not(.active) {
    cursor: pointer;
  }
}

.active {
  background: #46b0c6;
}
